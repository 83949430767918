import React, { useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, lighten, IconButton } from '@mui/material';
import { Spinner, Alert } from 'react-bootstrap';

import MoreSharpIcon from '@mui/icons-material/MoreSharp';
import { useDispatch } from 'react-redux';
import { getCertificatesByPolicyId } from '../../../api/CertificateService.js';
//import ModalActionsCertificate from './ModalActionsCertificate.js';
import { getInsuredsByPolicyId } from '../../../api/InsuredService.js';
import { Button, ButtonGroup } from 'react-bootstrap';
import { sendEmailCertificate, sendInvitation } from '../../../api/emailService.js';

// Función para transformar datos
const transformData = (data) => {
  return {
    ...data,
    gender: data.gender === 'F' ? 'Masculino' : data.gender === 'F' ? 'Femenino' : data.gender ,
  };
};

const MainTable = ({ policy_id }) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Estado para controlar la fila seleccionada
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la visibilidad del modal
  const [policyNumber, setPolicyNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar el estado de carga
  const [alertMessage, setAlertMessage] = useState(''); // Estado para manejar el mensaje de éxito/error
  const [showAlert, setShowAlert] = useState(false); // Estado para mostrar el alert

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [policy_id]);

  const transformData = (type) => {
    const Gender = {
      'M': 'Masculino',
      'F': 'Femenino',
      'masculino': 'Masculino',
      'femenino': 'Femenino',
    };
  
    return Gender[type] || type || 'Error';
  };
  
  const fetchData = async () => {
    try {
      const response = await getInsuredsByPolicyId(policy_id);
      setPolicyNumber(response.policy_number)
      setData(response.insureds);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'details',
        header: 'Detalles',
        columns: [
          { accessorKey: 'complete_name', header: <div>Nombre<br />completo</div>, size: 50 },
          { accessorKey: 'gender', header: <div>Género</div>, size: 20, Cell: ({ cell }) => transformData(cell.getValue()) },
          { accessorKey: 'certificate_number', header: <div>Núm. de certificado</div>, size: 20 },
          { accessorKey: 'subgroup_name', header: <div>Nombre de subgrupo</div>, size: 20 },
          {
            accessorFn: (row) => row.email || row.work_email,
            header: 'Correo',
            size: 50,
            show: false,
          },
        ],
      },
    ],
    []
  );


  const handleSendEmail = async (row, type) => {
    const emailToSend = row.email || row.work_email;
    const name = row.complete_name || row.first_name; // `first_name` debería ser `row.first_name`

    const formData = {
      emailToSend: emailToSend,
      first_name: name
    };

    try {
      if (type === 'certificate') {
        setIsLoading(true)
        await sendEmailCertificate(row.certificate_id, formData);
        setIsLoading(false)

        alert('Correo de certificado enviado correctamente'); // Mensaje en caso de éxito
      } else if (type === 'seguwallet') {
        setIsLoading(true)
        await sendInvitation(row.certificate_id, formData);
        alert('Invitación SeguWallet enviada correctamente'); // Mensaje en caso de éxito
      }
    } catch (error) {
      alert('Error enviando el correo: ' + error.message); // Mensaje en caso de error
    } finally {
      setIsLoading(false); // Detener el estado de carga
    }
  };

  const handleOpenModalEmailLog = (row) => {
    setSelectedRow(row); // Establece la fila seleccionada
  }

  const table = useMaterialReactTable({
    columns: columns[0].columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div>
        <Box>
          <Button
            variant="primary"
            onClick={() => handleSendEmail(row.original, "certificate")}
            style={{ width: '180px', fontSize: '14px' }}
            className="mb-2"
            disabled={isLoading} // Desactivar el botón si está cargando
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Envio Certificado'}
          </Button>
          <Button
            variant="danger"
            onClick={() => handleSendEmail(row.original, "seguwallet")}
            style={{ width: '180px', fontSize: '14px' }}
            className="mb-2"
            disabled={isLoading} // Desactivar el botón si está cargando
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Invitación SeguWallet'}
          </Button>
          <Button
            variant="danger"
            style={{ width: '180px', fontSize: '14px' }}
            disabled // Este botón siempre estará desactivado
          >
            Ver Registro de Correos
          </Button>
        </Box>
      </div>
    ),
    muiFilterTextFieldProps: ({ column }) => ({
      label: `${column.columnDef.header}`,
    }),
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      placeholder: 'Busqueda',
      size: 'medium',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderTopToolbar: ({ table }) => {
      return (
        <div>
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.9rem',
              p: '8px',
              justifyContent: 'center',
            })}
          >
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
          </Box>
        </div>
      );
    },
  });

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns[0].columns.map((col) => ({
          ...col,
        }))}
      />

      {/* {modalOpen && selectedRow && (
        <ModalActionsCertificate
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          data={selectedRow}
        />
      )} */}
    </>
  );
};

const MainTableInsured = ({ policy_id }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MainTable policy_id={policy_id} />
  </LocalizationProvider>
);

export default MainTableInsured;
