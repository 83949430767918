import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');
// Método para obtener todas las aseguradoras
export const getInsurances = async () => {
  console.log("Entrando a getInsurances");
  try {
    const response = await axios.get(`${apiUrl}/insurer`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getInsurances:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching insurances:', error);
    throw error;
  }
};

// Método para agregar una nueva aseguradora
export const postInsurance = async (data) => {
  console.log("Entrando a postInsurance: ", data);

  try {
    const response = await axios.post(`${apiUrl}/insurer`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Repuesta del postInsurance:", response.data)

    return response.data;
  } catch (error) {
    console.error('Error adding insurance:', error);    
    console.log("Repuesta del postInsurance Error:", data)
    throw error;
  }
};

// Método para actualizar una aseguradora existente
export const updateInsurance = async (insuranceId, insuranceData) => {
  console.log("Datos a actualizar", insuranceData)
  try {
    const response = await axios.patch(`${apiUrl}/insurer/${insuranceId}`, insuranceData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        
      },
    });
    console.log("Respuesta:", response);
    return response.data;
    
  } catch (error) {
    console.error('Error updating insurance:', error);
    throw error;
  }
};
export const deleteInsurance = async (insuranceId) => {
  try {
    const response = await axios.delete(`${apiUrl}/insurer/${insuranceId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;
    
  } catch (error) {
    console.error('Error deleting insurance:', error);
    throw error;
  }
};
