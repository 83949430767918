import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../global';

const TestComponent = () => {
    const [formData, setFormData] = useState({
        certificate_number: '',
        com_id_policy: '',
        policy_number: '',
        concept: '',
        policy_type: '',
        policy_holder: '',
        type: '',
        policy_branch: '',
        pool: '',
        start_date_coverage: '',
        end_date_coverage: '',
        renewable: '',
        administration_type: '',
        currency: '',
        net_premium: '',
        right_fee: '',
        financing: '',
        iva: '',
        fractional_payment_surcharge: '',
        policy_fee: '',
        payment_method: '',
        commission_percentage: '',
        bonus1: '',
        bonus2: '',
        total_premium: '',
        admin_email: '',
        policy_holder_id: '',
        organization_id: '',
        executive_name: '',
        insurer_name: '',
    });
    const token = localStorage.getItem('token');

    const [pdfData, setPdfData] = useState([]);

    const categories = [
        'Declaración',
        'Endoso',
        'Certificado',
        'Credencial Digital',
        'Recibo',
        'Factura Póliza',
        'Presentación',
        'Otros',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);

        const updatedPdfData = selectedFiles.map((file) => ({
            file,
            category: categories[0], // Asigna una categoría por defecto
        }));

        setPdfData((prevPdfData) => [...prevPdfData, ...updatedPdfData]);
    };

    const handleCategoryChange = (index, selectedCategory) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData[index].category = selectedCategory;
        setPdfData(updatedPdfData);
    };

    const handleRemoveFile = (index) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData.splice(index, 1); // Eliminar el archivo en el índice dado
        setPdfData(updatedPdfData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const form = new FormData();
    
        // Agregar datos del formulario
        Object.keys(formData).forEach((key) => {
            form.append(key, formData[key]);
        });
    
        // Agregar archivos PDF con categorías
        pdfData.forEach((data, index) => {
            form.append(`pdf_${data.category.toLowerCase().replace(/\s+/g, '_')}[]`, data.file);
        });
    
        // Imprimir el contenido del FormData
        form.forEach((value, key) => {
            if (value instanceof File) {
                console.log(`${key}: ${value.name}`); // Imprime el nombre del archivo
            } else {
                console.log(`${key}: ${value}`); // Imprime el valor
            }
        });
    
        try {
            const response = await axios.post(`${apiUrl}/policies`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Número de Certificado:
                <input
                    type="text"
                    name="certificate_number"
                    value={formData.certificate_number}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                ID de Póliza:
                <input
                    type="text"
                    name="com_id_policy"
                    value={formData.com_id_policy}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Número de Póliza:
                <input
                    type="text"
                    name="policy_number"
                    value={formData.policy_number}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Concepto:
                <input
                    type="text"
                    name="concept"
                    value={formData.concept}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Tipo de Póliza:
                <input
                    type="text"
                    name="policy_type"
                    value={formData.policy_type}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Titular:
                <input
                    type="text"
                    name="policy_holder"
                    value={formData.policy_holder}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Tipo:
                <input
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Sucursal:
                <input
                    type="text"
                    name="policy_branch"
                    value={formData.policy_branch}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Pool:
                <input
                    type="text"
                    name="pool"
                    value={formData.pool}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Fecha de Inicio de Cobertura:
                <input
                    type="date"
                    name="start_date_coverage"
                    value={formData.start_date_coverage}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Fecha de Fin de Cobertura:
                <input
                    type="date"
                    name="end_date_coverage"
                    value={formData.end_date_coverage}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Renovable:
                <input
                    type="text"
                    name="renewable"
                    value={formData.renewable}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Tipo de Administración:
                <input
                    type="text"
                    name="administration_type"
                    value={formData.administration_type}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Moneda:
                <input
                    type="text"
                    name="currency"
                    value={formData.currency}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Prima Neta:
                <input
                    type="text"
                    name="net_premium"
                    value={formData.net_premium}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Cuota:
                <input
                    type="text"
                    name="right_fee"
                    value={formData.right_fee}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Financiamiento:
                <input
                    type="text"
                    name="financing"
                    value={formData.financing}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                IVA:
                <input
                    type="text"
                    name="iva"
                    value={formData.iva}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Recargo por Pago Fraccionado:
                <input
                    type="text"
                    name="fractional_payment_surcharge"
                    value={formData.fractional_payment_surcharge}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Cuota de Póliza:
                <input
                    type="text"
                    name="policy_fee"
                    value={formData.policy_fee}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Método de Pago:
                <input
                    type="text"
                    name="payment_method"
                    value={formData.payment_method}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Porcentaje de Comisión:
                <input
                    type="text"
                    name="commission_percentage"
                    value={formData.commission_percentage}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Bono 1:
                <input
                    type="text"
                    name="bonus1"
                    value={formData.bonus1}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Bono 2:
                <input
                    type="text"
                    name="bonus2"
                    value={formData.bonus2}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Prima Total:
                <input
                    type="text"
                    name="total_premium"
                    value={formData.total_premium}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Email de Administrador:
                <input
                    type="email"
                    name="admin_email"
                    value={formData.admin_email}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                ID del Titular:
                <input
                    type="text"
                    name="policy_holder_id"
                    value={formData.policy_holder_id}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                ID de la Organización:
                <input
                    type="text"
                    name="organization_id"
                    value={formData.organization_id}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Nombre del Ejecutivo:
                <input
                    type="text"
                    name="executive_name"
                    value={formData.executive_name}
                    onChange={handleInputChange}
                />
            </label>

            <label>
                Nombre de la Aseguradora:
                <input
                    type="text"
                    name="insurer_name"
                    value={formData.insurer_name}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Subir PDFs:
                <input
                    type="file"
                    accept=".pdf"
                    multiple
                    onChange={handleFileChange}
                />
            </label>

            {pdfData.length > 0 && (
                <div>
                    <h3>Categorías de PDFs</h3>
                    {pdfData.map((pdf, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            <label>
                                Archivo: {pdf.file.name}
                                <select
                                    value={pdf.category}
                                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                                >
                                    {categories.map((category, i) => (
                                        <option key={i} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <button type="button" onClick={() => handleRemoveFile(index)}>Eliminar</button>
                        </div>
                    ))}
                </div>
            )}

            <button type="submit">Enviar</button>
        </form>
    );
};

export default TestComponent;
