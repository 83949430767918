import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.data = action.payload;
    },
    addOrganization: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setOrganizations, addOrganization } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;

export default organizationSlice.reducer;
