import React, { useState } from 'react';
import { PostUsers } from '../api/userService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function UserForm() {
  const [buttonAdd, setbuttonAdd] = useState(false);

  const [formData, setFormData] = useState({com_id_user:'',first_name:'',last_name_paternal:'',last_name_maternal:'',date_birth:'',email:'',work_email:'',rfc:'',curp:'',phone_office:'',phone_personal:'',company_name:'',position:'',employee_number:'',password:''});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    PostUsers(formData)
    setbuttonAdd(false); // Después de enviar el formulario, cambia el estado de buttonAdd a false
    setFormData({com_id_user:'',first_name:'',last_name_paternal:'',last_name_maternal:'',date_birth:'',email:'',work_email:'',rfc:'',curp:'',phone_office:'',phone_personal:'',company_name:'',position:'',employee_number:'',password:''}); // Limpia los datos del formulario
    MySwal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: 'El usuario se ha añadido correctamente.',
    });
  };


  if (!buttonAdd) {
    return (
      <button className="btn btn-primary" onClick={() => setbuttonAdd(true)}>Nuevo</button>
      
    );
  }


  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">

      <div className="mb-3">
        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} className="form-control" placeholder="Primer Nombre " />
      </div>
      <div className="mb-3">
        <input type="text" name="last_name_paternal" value={formData.last_name_paternal} onChange={handleChange} className="form-control" placeholder="Apellido Paterno " />
      </div>
      <div className="mb-3">
        <input type="text" name="last_name_maternal" value={formData.last_name_maternal} onChange={handleChange} className="form-control" placeholder="Apellido Materno " />
      </div>
      <div className="mb-3">
        <input type="text" name="email" value={formData.email} onChange={handleChange} className="form-control" placeholder="Email " />
      </div>
      <div className="mb-3">
        <input type="text" name="certificate_number" value={formData.certificate_number} onChange={handleChange} className="form-control" placeholder="Numero de certificado " />
      </div>

      
      <button type="submit" className="btn btn-primary">Submit</button>
      <button className="btn btn-danger" onClick={() => setbuttonAdd(false)}>Cancelar</button>

    </form>
  );
}


export default UserForm;
