import React, { useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { postPools } from '../../../features/pools/poolService';

const MySwal = withReactContent(Swal);

function PoolForm() {
  const [botonAdd, setBotonAdd] = useState(false);
  const [formData, setFormData] = useState({ name: ''});


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    postPools(formData)
    setBotonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
    setFormData({ name: '' }); // Limpia los datos del formulario
    MySwal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: 'Se ha añadido correctamente.',
    });    
  };


  if (!botonAdd) {
    return (
      <div>
        {!botonAdd && (
          <div>
            <h2> Pools</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setBotonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <h2> Pools</h2>
      
      <div className="mb-3">
        <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre" />
      </div>
     

      <button type="submit" className="btn btn-primary">Submit</button>
      <button className="btn btn-danger" onClick={() => setBotonAdd(false)}>Cancelar</button>

    </form>
  );
}


export default PoolForm;
