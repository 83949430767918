import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los ejecutivos
export const getCarousel = async () => {
  console.log("Entrando a getCarousel");
  try {
    const response = await axios.get(`${apiUrl}/carousel`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getCarousel:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching Images of Carousels:', error);
    throw error;
  }
};

// Método para agregar una nueva foto para el carrusel
export const postCarousel = async (carouselData) => {
  try {
    const response = await axios.post(`${apiUrl}/carousel`, carouselData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${carouselData}`)
    return response.data;
  } catch (error) {
    console.error('Error adding Image of Carousel:', error);
    throw error;
  }
};


export const deleteCarousel = async (imageId) => {
  try {
    const response = await axios.delete(`${apiUrl}/carousel/${imageId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting Carousel Image:', error);
    throw error;
  }
};

//Cambiar estado de visualizacion de una imagen
export const changeActivate = async (imageId) => {
  console.log("Token", token)
  console.log(`${apiUrl}/carousel/${imageId}/activate`)
  try {
    const response = await axios.post(
      `${apiUrl}/carousel/${imageId}/activate`,
      {}, // Debes pasar un objeto vacío para el cuerpo de la solicitud POST si no hay datos que enviar
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error activando Carousel Image:', error);
    throw error;
  }
};
