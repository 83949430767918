import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los Organizaciones
export const getOrganizations = async () => {
  console.log("Entrando a getOrganizations");
  try {
    const response = await axios.get(`${apiUrl}/organizations`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getOrganizations:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};
// Método para obtener una Organizacion dando el id
export const getOrganizationById = async (id) => {
  console.log("Entrando a getOrganizationById");
  try {
    const response = await axios.get(`${apiUrl}/organizations/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};
// Método para agregar una nueva organizacion

export const postOrganization = async (data) => {
  try {
    console.log("ExecutiveService Post, Datos: ", data);

    const response = await axios.post(`${apiUrl}/organization`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${data}`)
    return response.data;
  } catch (error) {
    console.error('Error adding organization:', error);
    throw error;
  }
};
// Método para actualizar ejecutivo existente
export const updateOrganization = async (id, data) => {
  console.log("Datos a actualizar", data);
  try {
    const response = await axios.patch(`${apiUrl}/organization/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating organization:', error);
    throw error;
  }
};
export const deleteOrganization = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/organization/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting organization:', error);
    throw error;
  }
};
export const findPolicyByOrganization = async (organizationId) => {
  try {
    const response = await axios.get(`${apiUrl}/policies/findPolicyByOrganization/${organizationId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;

  } catch (error) {
    console.error('Error fetching policies:', error);
  }
};