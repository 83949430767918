import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const executiveSlice = createSlice({
  name: 'executive',
  initialState,
  reducers: {
    setExecutive: (state, action) => {
      state.data = action.payload;
    },
    addExecutive: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setExecutive, addExecutive } = executiveSlice.actions;
export const executiveReducer = executiveSlice.reducer;

export default executiveReducer.reducer;
