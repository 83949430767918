import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addEmailToCertificate } from '../../api/InsuredService';

const ModalAddEmail = ({ showModal, handleClose, selectedId }) => {
    const MySwal = withReactContent(Swal);
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addEmailToCertificate(selectedId, { email }); // Cambiado para enviar un objeto con la propiedad email

            MySwal.fire({
                title: '¡Éxito!',
                text: 'El email se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            });
            handleClose(); // Cerrar el modal después del éxito
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el email.',
                footer: `<pre>${error.message || 'Error desconocido'}</pre>`,
            });
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Introduce el nuevo email"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAddEmail;
