import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl, grmSeguWalletLogo} from '../../global'
const LoginForm = ({ onLogin }) => {
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const token = localStorage.getItem('token');
  if (token) {
    // Autenticar automáticamente al usuario utilizando el token
    onLogin(token);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiUrl + "/login", {
        email,
        password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token); // Almacena el token en el localStorage
      onLogin(token);
      window.location.reload()
    } catch (err) {
      setError('Credenciales incorrectas. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="w-100 px-3 mx-auto">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h3 className="card-title text-center mb-4">Inicio de Sesión</h3>
              <img src={grmSeguWalletLogo} className="rounded mx-auto d-block" alt="LOGO" width="150" />

              <h4 className="card-subtitle text-center mb-1">SeguWallet Admin</h4>

              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Correo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Ingrese su usuario"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Ingrese su contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block">Iniciar Sesión</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
