import React from 'react';
import { Modal, Button, Container, Row, Col, Table, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {updateStatusCertificate} from "../../../api/CertificateService";
const MySwal = withReactContent(Swal);

const ModalActionsCertificate = ({ show, onHide, data }) => {
  const [showOrgForm, setShowOrgForm] = React.useState(false);
  const [showSubgroup, setShowSubgroup] = React.useState(false);
  const [showCertificates, setShowCertificates] = React.useState(false);
  const [showInsured, setShowInsured] = React.useState(false);
  const [editForm, setEditForm] = React.useState(false);
  const [formData, setFormData] = React.useState({ certificate_number: data.certificate_number, status: data.status, details: data.details });


  if (!data) return null;

  const handleClose = () => {
    onHide();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data.id_certificate)
    try {
      await updateStatusCertificate(data.id_certificate,formData);
      setEditForm(false)
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El certificado se ha actualizado correctamente.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        html: `Ha ocurrido un error al Actualizar el certificado.<br/><strong>${error.response.data.message}</strong>`,
      });
    }
  };
  const handleEdit = () => {
    if (editForm) {
      setEditForm(false)
    } else {
      setEditForm(true)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Información del Certificado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={6}>
              {!editForm && (<p><strong>Número del Certificado:</strong> {data.certificate_number}</p>)}
              {editForm && (
                <div>
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      name="certificate_number"
                      className="form-control"
                      placeholder="Número del Certificado"
                      value={formData.certificate_number}
                      onChange={handleChange}

                    />
                    <label htmlFor={`Número del Certificado`}>Número del Certificado</label>
                  </div>
                  <div className="form-floating mb-2">
                    <select
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      className="form-select"
                      id="status"
                    >
                      <option value="pending">En Proceso</option>
                      <option value="released">Liberado</option>
                    </select>
                    <label htmlFor="status">Estatus</label>
                  </div>
                  <div className="form-floating mb-2">
                    <textarea
                      type="text"
                      value={formData.details}
                      name="details"
                      className="form-control"
                      placeholder="Detalles"
                      onChange={handleChange}
                    />
                    <label htmlFor={`Detalles`}>Detalles</label>
                  </div>
                </div>
              )}
              {!editForm && (
                <div>
                  <p><strong>Estatus:</strong> {data.status}</p>
                  {data.status === 'En proceso' && <p><strong>Detalles:</strong> {data.details || 'Sin detalles'}</p>}
                </div>
              )}

            </Col>
            <Col md={4}>
              <p><strong>Numero del subgrupo:</strong> {data.subgroup_number}</p>
              <p><strong>Nombre del subgrupo:</strong> {data.subgroup_name}</p>
            </Col>

          </Row>
          <hr />
          {data.pdfs.length > 0 && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Archivos del Certificado</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Categoría</th>
                        <th>Nombre del Archivo</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.pdfs.map((pdf, index) => (
                        <tr key={index}>
                          <td>{pdf.category || 'NA'}</td>
                          <td>{pdf.file_name}</td>
                          <td>
                            <a href={pdf.public_link} target="_blank" rel="noopener noreferrer">Ver PDF</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {data.insureds && data.insureds.length > 0 && (
            <Accordion className="mt-3">
              {data.insureds.map((insured, insuredIndex) => (
                <Accordion.Item eventKey={insuredIndex} key={insured.id_insured}>
                  <Accordion.Header>
                    Asegurado {insuredIndex + 1} {({ holder: 'Titular', spouse: 'Cónyuge', dependent: 'Dependiente' }[insured.relationship] || insured.relationship)}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="container">
                      {/* Primer conjunto de columnas */}
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`first_name_${insured.id_insured}`}
                              name="first_name"
                              className="form-control"
                              placeholder="Primer nombre"
                              defaultValue={insured.first_name || 'NA'}
                              disabled
                            />
                            <label htmlFor={`first_name_${insured.id_insured}`}>Primer nombre</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`last_name_paternal_${insured.id_insured}`}
                              name="last_name_paternal"
                              className="form-control"
                              placeholder="Apellido Paterno"
                              defaultValue={insured.last_name_paternal || 'NA'}
                              disabled
                            />
                            <label htmlFor={`last_name_paternal_${insured.id_insured}`}>Apellido Paterno</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`last_name_maternal_${insured.id_insured}`}
                              name="last_name_maternal"
                              className="form-control"
                              placeholder="Apellido Materno"
                              defaultValue={insured.last_name_maternal || 'NA'}
                              disabled
                            />
                            <label htmlFor={`last_name_maternal_${insured.id_insured}`}>Apellido Materno</label>
                          </div>
                        </div>
                      </div>

                      {/* Segundo conjunto de columnas */}
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`relationship_${insured.id_insured}`}
                              name="relationship"
                              className="form-control"
                              placeholder="Relación"
                              defaultValue={({ holder: 'Titular', spouse: 'Cónyuge', dependent: 'Dependiente' }[insured.relationship] || 'NA')}
                              disabled
                            />
                            <label htmlFor={`relationship_${insured.id_insured}`}>Relación</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="date"
                              id={`date_birth_${insured.id_insured}`}
                              name="date_birth"
                              className="form-control"
                              placeholder="Fecha de Nacimiento"
                              defaultValue={insured.date_birth || 'NA'}
                              disabled
                            />
                            <label htmlFor={`date_birth_${insured.id_insured}`}>Fecha de Nacimiento</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="date"
                              id={`current_antiquity_${insured.id_insured}`}
                              name="current_antiquity"
                              className="form-control"
                              placeholder="Antigüedad Actual"
                              defaultValue={insured.current_antiquity || 'NA'}
                              disabled
                            />
                            <label htmlFor={`current_antiquity_${insured.id_insured}`}>Antigüedad Actual</label>
                          </div>
                        </div>
                      </div>

                      {/* Tercer conjunto de columnas */}
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="date"
                              id={`total_antiquity_${insured.id_insured}`}
                              name="total_antiquity"
                              className="form-control"
                              placeholder="Antigüedad Total"
                              defaultValue={insured.total_antiquity || 'NA'}
                              disabled
                            />
                            <label htmlFor={`total_antiquity_${insured.id_insured}`}>Antigüedad Total</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <select
                              id={`gender_${insured.id_insured}`}
                              name="gender"
                              className="form-control"
                              defaultValue={insured.gender || 'NA'}
                              disabled
                            >
                              <option value="">Seleccionar Género</option>
                              <option value="M">Masculino</option>
                              <option value="F">Femenino</option>
                              <option value="O">Otro</option>
                            </select>
                            <label htmlFor={`gender_${insured.id_insured}`}>Género</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="email"
                              id={`email_${insured.id_insured}`}
                              name="email"
                              className="form-control"
                              placeholder="Correo"
                              defaultValue={insured.email || 'NA'}
                              disabled
                            />
                            <label htmlFor={`email_${insured.id_insured}`}>Email</label>
                          </div>
                        </div>
                      </div>

                      {/* Cuarto conjunto de columnas */}
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="email"
                              id={`work_email_${insured.id_insured}`}
                              name="work_email"
                              className="form-control"
                              placeholder="Email de Trabajo"
                              defaultValue={insured.work_email || 'NA'}
                              disabled
                            />
                            <label htmlFor={`work_email_${insured.id_insured}`}>Email de Trabajo</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`rfc_${insured.id_insured}`}
                              name="rfc"
                              className="form-control text-uppercase"
                              placeholder="RFC"
                              defaultValue={insured.rfc || 'NA'}
                              disabled
                            />
                            <label htmlFor={`rfc_${insured.id_insured}`}>RFC</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`curp_${insured.id_insured}`}
                              name="curp"
                              className="form-control text-uppercase"
                              placeholder="CURP"
                              defaultValue={insured.curp || 'NA'}
                              disabled
                            />
                            <label htmlFor={`curp_${insured.id_insured}`}>CURP</label>
                          </div>
                        </div>
                      </div>

                      {/* Quinto conjunto de columnas */}
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`phone_office_${insured.id_insured}`}
                              name="phone_office"
                              className="form-control"
                              placeholder="Teléfono de Oficina"
                              defaultValue={insured.phone_office || 'NA'}
                              disabled
                            />
                            <label htmlFor={`phone_office_${insured.id_insured}`}>Teléfono de Oficina</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              id={`phone_personal_${insured.id_insured}`}
                              name="phone_personal"
                              className="form-control"
                              placeholder="Teléfono Personal"
                              defaultValue={insured.phone_personal || 'NA'}
                              disabled
                            />
                            <label htmlFor={`phone_personal_${insured.id_insured}`}>Teléfono Personal</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Container>
        <hr />
      </Modal.Body>
      <Modal.Footer>
        {!editForm ? (
          <Button variant="info" onClick={handleEdit}>Editar</Button>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="primary" onClick={handleEdit}>Cancelar</Button>
            <Button variant="success" onClick={handleSubmit}>Enviar Cambios</Button>
          </div>
        )}
        <Button variant="danger" onClick={handleClose}>Cerrar</Button>
      </Modal.Footer>

      {/* {showSubgroup && <SubgroupForm buttonAddForeign={true} onClose={handleSubgroupClose} />}
      {showCertificates && <CertificatesForm buttonAddForeign={true} onClose={handleCertificateClose} />}
      {showInsured && <SubgroupForm buttonAddForeign={true} onClose={handleSubgroupClose} />}
      {showOrgForm && <OrganizationForm />} */}
    </Modal>
  );
};

export default ModalActionsCertificate;
