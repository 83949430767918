import React from 'react';
import { Modal, Button, Container, Row, Col, Table, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateStatusCertificate } from "../../../api/CertificateService";
import { updateSubgroup } from '../../../api/SubgroupService';
const MySwal = withReactContent(Swal);

const ModalActionsSubgroup = ({ show, onHide, data }) => {
  const [showOrgForm, setShowOrgForm] = React.useState(false);
  const [showSubgroup, setShowSubgroup] = React.useState(false);
  const [showCertificates, setShowCertificates] = React.useState(false);
  const [showInsured, setShowInsured] = React.useState(false);
  const [editForm, setEditForm] = React.useState(false);
  const [formData, setFormData] = React.useState({ subgroup_number: data.subgroup_number, subgroup_name: data.subgroup_name, hospital_level: data.hospital_level });


  if (!data) return null;

  const handleClose = () => {
    onHide();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data.id_subgroup)
    try {
      await updateSubgroup(data.id_subgroup, formData);
      setEditForm(false)
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El Subgrupo se ha actualizado correctamente.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        html: `Ha ocurrido un error al Actualizar el Subgrupo .<br/><strong>${error.response.data.message}</strong>`,
      });
    }
  };
  const handleEdit = () => {
    if (editForm) {
      setEditForm(false)
    } else {
      setEditForm(true)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Información del Subgrupo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={6}>
              {editForm && (
                <div>
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      value={formData.subgroup_number}
                      name="subgroup_number"
                      className="form-control"
                      placeholder="subgroup_number"
                      onChange={handleChange}
                    />
                    <label htmlFor={`subgroup_number`}>Numero de subgrupo</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      value={formData.subgroup_name}
                      name="subgroup_name"
                      className="form-control"
                      placeholder="subgroup_name"
                      onChange={handleChange}
                    />
                    <label htmlFor={`subgroup_name`}>Nombre del Subgrupo</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      value={formData.hospital_level}
                      name="hospital_level"
                      className="form-control"
                      placeholder="hospital_level"
                      onChange={handleChange}
                    />
                    <label htmlFor={`hospital_level`}>Nivel Hospitalario</label>
                  </div>
                </div>
              )}
            </Col>
            <Col md={12}>
              {!editForm && (

                <div>
                  <p><strong>Numero del subgrupo:</strong> {data.subgroup_number}</p>
                  <p><strong>Nombre del subgrupo:</strong> {data.subgroup_name}</p>
                  <p><strong>Nivel Hospitalario:</strong> {data.hospital_level}</p>
                </div>
              )}
            </Col>

          </Row>
          <hr />
          {data.pdfs.length > 0 && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Archivos del Subgrupo</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Categoría</th>
                        <th>Nombre del Archivo</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.pdfs.map((pdf, index) => (
                        <tr key={index}>
                          <td>{pdf.category || 'NA'}</td>
                          <td>{pdf.file_name}</td>
                          <td>
                            <a href={pdf.public_link} target="_blank" rel="noopener noreferrer">Ver PDF</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

        </Container>
        <hr />
      </Modal.Body>
      <Modal.Footer>
        {!editForm ? (
          <Button variant="info" onClick={handleEdit}>Editar</Button>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="primary" onClick={handleEdit}>Cancelar</Button>
            <Button variant="success" onClick={handleSubmit}>Enviar Cambios</Button>
          </div>
        )}
        <Button variant="danger" onClick={handleClose}>Cerrar</Button>
      </Modal.Footer>

      {/* {showSubgroup && <SubgroupForm buttonAddForeign={true} onClose={handleSubgroupClose} />}
      {showCertificates && <CertificatesForm buttonAddForeign={true} onClose={handleCertificateClose} />}
      {showInsured && <SubgroupForm buttonAddForeign={true} onClose={handleSubgroupClose} />}
      {showOrgForm && <OrganizationForm />} */}
    </Modal>
  );
};

export default ModalActionsSubgroup;
