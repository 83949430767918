import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getInsurances, updateInsurance, deleteInsurance } from '../../src/api/insurerService';
import InsurersForm from './InsurerForm';
import { pdfLogo } from "../global";
import { deletePDF } from '../api/PdfService';
import { Modal, Button, Form } from 'react-bootstrap';
import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
const MySwal = withReactContent(Swal);

function InsurersList() {
  const dispatch = useDispatch();
  const insurers = useSelector((state) => state.insurer.data);
  const user = useSelector((state) => state.users.data);


  const [insurersData, setInsurers] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!user.length) {
      infoGlobal.fetchRole();
    }
    if (!insurers.length) {
      infoGlobal.fetchInsurers();
    }
  }, [user, dispatch]);

  const openPdf = (pdfUrl) => {
    console.log(pdfUrl)
    const newWindow = window.open(pdfUrl);
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.fetch(pdfUrl, {
        });
      };
    }
  };


  const handleEdit = (id) => {
    setEditingId(id);
    const insurerToEdit = insurers.find(insurer => insurer.id_insurer === id);
    setFormData({ ...insurerToEdit });
    setShowModal(true);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setFormData({});
    setShowModal(false);
  };

  const handleSaveEdit = async () => {
    try {
      await updateInsurance(editingId, formData); // Asegúrate de que formData esté completo y correcto
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(infoGlobal.fetchInsurers()); // Asegúrate de que fetchInsurers esté actualizando correctamente la lista
      setShowModal(false);
    } catch (error) {
      console.error('Error updating insurance:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };


  const handleDeleteInsurer = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar esta aseguradora?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteInsurance(id);
          setInsurers(prevInsurers => prevInsurers.filter(insurer => insurer.id_insurer !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'La aseguradora se ha eliminado correctamente.',
          }).then(infoGlobal.fetchInsurers());
        } catch (error) {
          console.error('Error deleting insurer:', error);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al eliminar la aseguradora.',
          });
        }
      }
    });
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleDeletePDF = async (insurerId, pdfId) => {
    try {
      await deletePDF(pdfId);
      console.log(`Eliminar PDF ${pdfId} de la aseguradora ${insurerId}`);
      infoGlobal.fetchInsurers();
    } catch (error) {
      console.error('Error deleting PDF:', error);
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        title: '¡Error!',
        text: 'Ha ocurrido un error al eliminar el PDF.',
      });
    }
  };

      {/* <InsurersForm updateInsuranceList={infoGlobal.fetchInsurers()} /> */}
  return (
    <div>
      <InsurersForm updateInsuranceList />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Logo</th>
            <th scope="col">Nombre Comercial</th>
            <th scope="col">Clave de Agente</th>
            <th scope="col">Condiciones Generales</th>
            <th scope="col">Teléfono de reclamaciones</th>
            <th scope="col">Teléfono de servicio</th>
            <th scope="col">RFC</th>
            <th scope="col">Pagina</th>
            <th scope="col">Dirección Fiscal</th>
            <th scope="col">PDFs</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {insurers.map(insurer => (
            <tr key={insurer.id_insurer}>
              <td>
                <img src={insurer.logo_link} alt={insurer.commercial_name} style={{ width: '50px', marginRight: '10px' }} />
              </td>
              <td>{insurer.commercial_name}</td>
              <td>{insurer.agent_key}</td>
              <td>
                <a href={insurer.general_conditions} target="_blank" rel="noopener noreferrer">Condiciones Generales</a>
              </td>
              <td>{insurer.phone_claims}</td>
              <td>{insurer.phone_service}</td>
              <td>{insurer.rfc}</td>
              <td>
                <a href={insurer.website} target="_blank" rel="noopener noreferrer">Sitio Web</a>
              </td>
              <td>{insurer.fiscal_address}</td>

              <td>
                {insurer.pdfs.map((pdf, index) => (
                  <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                    <a href="#" onClick={() => openPdf(pdf.public_link)}>
                      <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                    </a>
                    <button
                      className="btn btn-danger btn-sm"
                      style={{ position: 'absolute', top: '0', right: '0', padding: '0.2rem' }}
                      onClick={() => handleDeletePDF(insurer.id_insurer, pdf.id)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </td>
              <td>
                <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(insurer.id_insurer)}>Editar</button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteInsurer(insurer.id_insurer)}>Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Aseguradora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formLogoPath">
              <Form.Label>Logo</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFieldChange('logo_path', e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formCommercialName">
              <Form.Label>Nombre Comercial</Form.Label>
              <Form.Control type="text" defaultValue={formData.commercial_name} onChange={(e) => handleFieldChange('commercial_name', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formFiscalAddress">
              <Form.Label>Dirección Fiscal</Form.Label>
              <Form.Control type="text" defaultValue={formData.fiscal_address} onChange={(e) => handleFieldChange('fiscal_address', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formGeneralConditions">
              <Form.Label>Condiciones Generales</Form.Label>
              <Form.Control type="text" defaultValue={formData.general_conditions} onChange={(e) => handleFieldChange('general_conditions', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formPhoneClaims">
              <Form.Label>Teléfono de reclamaciones</Form.Label>
              <Form.Control type="text" defaultValue={formData.phone_claims} onChange={(e) => handleFieldChange('phone_claims', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formPhoneService">
              <Form.Label>Teléfono de servicio</Form.Label>
              <Form.Control type="text" defaultValue={formData.phone_service} onChange={(e) => handleFieldChange('phone_service', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formRFC">
              <Form.Label>RFC</Form.Label>
              <Form.Control type="text" defaultValue={formData.rfc} onChange={(e) => handleFieldChange('rfc', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formWebsite">
              <Form.Label>Página</Form.Label>
              <Form.Control type="text" defaultValue={formData.website} onChange={(e) => handleFieldChange('website', e.target.value)} />
            </Form.Group>

            {/* Mostrar sección para subir más PDFs */}
            <Form.Group controlId="formPDFs">
              <Form.Label>Subir PDFs adicionales</Form.Label>
              <Form.Control type="file" multiple onChange={(e) => handleFieldChange('pdfs', e.target.files)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEdit}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default InsurersList;
