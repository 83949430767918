import { createSlice } from '@reduxjs/toolkit';

const modalPolicySlice = createSlice({
  name: 'modalPolicy',
  initialState: {
    isOpen: false,
    policyData: null,
    selectedOrganization: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.policyData = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.policyData = null;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedOrganization } = modalPolicySlice.actions;

export const modalPolicyReducer = modalPolicySlice.reducer;

export default modalPolicySlice.reducer;
