import React, { useState, useEffect } from 'react';
import { postCertificate } from '../api/CertificateService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getPoliciesByHolder } from '../api/policyService';
import { getHolderByOrganization, getHolders } from '../api/PolicyHolderService';
import { getSubgroupByPolicyId } from '../api/CertificateService';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../global';
import { Accordion, Form, Button, Modal } from 'react-bootstrap';
import { initialCertificateData } from "../components/Helpers/initialData";
import ModalAddDependent from '../features/certificate/ModalAddDependent';
import { RenderDropzone } from '../features/pdfs/functionsPdf';
const MySwal = withReactContent(Swal);

function CertificatesForm({ buttonAddCertificate, buttonAddForeign, organizationForeign, policyHolderForeign, policyForeign, onClose }) {
  const dispatch = useDispatch();
  const [orgDatas, setOrgDatas] = useState([]);
  const organization = useSelector((state) => state.organizations.data);
  const [buttonAdd, setButtonAdd] = useState(false);
  const [formData, setFormData] = useState(initialCertificateData);
  const [policies, setPolicies] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [holders, setHolders] = useState([]);
  const [selectedHolder, setSelectedHolder] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [selectedSubgroup, setSelectedSubgroup] = useState('');
  const [certificateId, setCertificateId] = useState('');
  const [addInsured, setAddInsured] = useState(false);
  const [addAnotherCertificate, setAddAnotherCertificate] = useState(false);
  const [depentModal, setDepentModal] = useState(false);


  useEffect(() => {
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization)
    }
    if (buttonAddCertificate) {
      setButtonAdd(true)
    }
    if (buttonAddForeign) {
      setButtonAdd(true);
      console.log("organizationForeign", organizationForeign)
      console.log("policyHolderForeign", policyHolderForeign)
      console.log("policyForeign", policyForeign)
      handleForeignData(organizationForeign, policyHolderForeign, policyForeign)
      setFormData((prevState) => ({
        ...prevState,
        organization_id: organizationForeign,
        policy_holder_id: policyHolderForeign,
        policy_id: policyForeign,
      }));
      setSelectedHolder(policyHolderForeign);
      setSelectedPolicy(policyForeign)
    }
  }, [organization, dispatch, buttonAddForeign, organizationForeign, policyHolderForeign, policyForeign]);

  const handleForeignData = async (organizationForeign, policyHolderForeign, policyForeign) => {
    const dataHolders = await getHolderByOrganization(organizationForeign);
    setHolders(dataHolders.holders);
    const dataPolicies = await getPoliciesByHolder(policyHolderForeign);
    setPolicies(dataPolicies);
    try {
      const data = await getSubgroupByPolicyId(policyForeign);
      setSubgroups(data);
    } catch (error) {
      console.error('Error fetching Subgroups:', error);
    }
  }

  const closeModal = () => {
    if (onClose) {
      onClose();
    } else {
      setButtonAdd(false)
    }
  }
  const handleAddInsuredChange = () => {
    setAddInsured(!addInsured);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convertir a mayúsculas solo para campos específicos
    const upperCaseFields = ['rfc', 'curp'];
    const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;
    handleSelectOrganization(name, value);

    // Actualizar el estado
    setFormData({ ...formData, [name]: newValue });
  };
  const handleSelectOrganization = async (name, value) => {
    if (name === 'organization_id') {
      const dataHolders = await getHolderByOrganization(value);
      setHolders(dataHolders.holders);
    }
  }
  const handleHolderChange = async (event) => {
    const { name, value } = event.target;

    const holderId = value;
    console.log("Evento", event);
    setSelectedHolder(holderId);
    setSelectedPolicy('');
    setSelectedSubgroup('');
    setFormData({ ...formData, [name]: holderId });
    try {
      const response = await getPoliciesByHolder(holderId);
      const data = response;

      setPolicies(data);
    } catch (error) {
      console.error('Error fetching Policies:', error);
    }

  };

  const handlePolicyChange = async (event) => {
    const policyId = parseInt(event.target.value, 10); // Asegúrate de convertir el valor a entero si es necesario
    setSelectedPolicy(policyId);
    setSelectedSubgroup('');
    const selectedPolicy = policies.find(policy => policy.id_policy === policyId);
    setFormData({ ...formData, policy_id: policyId, admin_email: selectedPolicy.admin_email });

    try {
      const data = await getSubgroupByPolicyId(policyId);
      setSubgroups(data);
    } catch (error) {
      console.error('Error fetching Subgroups:', error);
    }
  };


  const handleSubgroupChange = (event) => {
    const subgroupId = event.target.value;
    setSelectedSubgroup(subgroupId);
    setFormData({ ...formData, subgroup_id: subgroupId });
  };

  const handleFileChange = (updatedFiles, category) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [category]: updatedFiles
    }));
    console.log(category, updatedFiles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await postCertificate(formData);
      setCertificateId(response.certificate.id_certificate)
      // Check if the required fields for dependent are filled
      const hasDependentData = formData.first_name && formData.last_name_paternal && formData.date_birth && formData.last_name_maternal;

      if (hasDependentData) {
        // Ask if they want to add a dependent
        const { isConfirmed: isAddDependent } = await MySwal.fire({
          icon: 'question',
          title: '¿Deseas añadir un dependiente?',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
        });

        if (isAddDependent) {
          setDepentModal(true)
          return;
        }
      }

      // Ask if they want to add another certificate
      const { isConfirmed } = await MySwal.fire({
        icon: 'question',
        title: '¿Deseas añadir otro certificado a la misma póliza?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      });

      if (!isConfirmed) {
        // Clear the whole form if the user doesn't want to add another certificate
        setSelectedHolder('');
        setSelectedPolicy('');
        setSelectedSubgroup('');
        setFormData(initialCertificateData);
      } else {
        // Reset some fields
        setFormData({
          ...formData,
          certificate_number: '',
          status: 'pending',
          details: '',
          email: '',
          work_email: '',
          first_name: '',
          last_name_paternal: '',
          last_name_maternal: '',
          relationship: '',
          date_birth: '',
          current_antiquity: '',
          total_antiquity: '',
          gender: '',
          rfc: '',
          curp: '',
          phone_office: '',
          phone_personal: '',
          pdf_certificates: [],
          pdf_digital_credentials: [],
          pdf_letter_of_antiquity: [],
          pdf_others: [],
        });
      }

      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El Certificado se ha añadido correctamente.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        title: 'Error',
        text: 'Hubo un problema al añadir el Certificado. Inténtalo de nuevo.',
      });
    }
  };

  if (!buttonAdd) {
    return (
      <div>
        {!buttonAdd && (
          <div>
            <h2> Certificados</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setButtonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>

      {depentModal && (
        <ModalAddDependent
          showModal={depentModal}
          certificate_id={certificateId}
          handleClose={closeModal} />
      )}

      <Modal dialogClassName="modal-xl" show={buttonAdd} onHide={() => closeModal()}>
        <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
          <Modal.Header closeButton>
            <Modal.Title>Certificados</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="form-floating mb-3">
                  <select
                    name="organization_id"
                    value={formData.organization_id}
                    onChange={handleChange}
                    className="form-control"
                    id="organization_id"
                  >
                    <option value="">Selecciona una organización</option>
                    {orgDatas.map(org => (
                      <option key={org.id_organization} value={org.id_organization}>
                        {org.id_organization} - {org.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="organization_id">Organización</label>
                </div>
                <div className="form-floating mb-3">
                  <select
                    name="policy_holder_id"
                    value={formData.policy_holder_id}
                    onChange={handleHolderChange}
                    className="form-control"
                    id="policy_holder_id"
                  >
                    <option value="">Selecciona un Contratante</option>
                    {holders.map((holder) => (
                      <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                        {holder.id_policy_holder} - {holder.business_name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="policy_holder_id">Contratante</label>
                </div>
                {selectedHolder && (
                  <div className="form-floating mb-3">
                    <select
                      name="policy_id"
                      value={selectedPolicy}
                      onChange={handlePolicyChange}
                      className="form-control"
                      id="policy_id"
                    >
                      <option value="">Selecciona una Póliza</option>
                      {policies.map(policy => (
                        <option key={policy.id_policy} value={policy.id_policy}>
                          {policy.policy_number}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="policy_id">Póliza</label>
                  </div>
                )}
                {/* {selectedPolicy && (
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="admin_email"
                      value={formData.admin_email}
                      onChange={handleChange}
                      className="form-control"
                      id="admin_email"
                      placeholder="Email del Admin Poliza"
                    />
                    <label htmlFor="admin_email">Email del Admin Poliza</label>
                  </div>
                )} */}
              </div>
              <div className="col-md-4">
                {selectedPolicy && selectedHolder && (
                  <div className="form-floating mb-3">
                    <select
                      name="subgroup_id"
                      value={selectedSubgroup}
                      onChange={handleSubgroupChange}
                      className="form-control"
                      id="subgroup_id"
                    >
                      <option value="">Selecciona un Subgrupo</option>
                      {subgroups.map(subgroup => (
                        <option key={subgroup.id_subgroup} value={subgroup.id_subgroup}>
                          {subgroup.subgroup_number} - {subgroup.subgroup_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="subgroup_id">Subgrupo</label>
                  </div>
                )}
                {selectedSubgroup && (
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="certificate_number"
                      value={formData.certificate_number}
                      onChange={handleChange}
                      className="form-control"
                      id="certificate_number"
                      placeholder="Número del Certificado"
                    />
                    <label htmlFor="certificate_number">Número de Certificado</label>
                  </div>
                )}
                {selectedSubgroup && selectedPolicy && selectedHolder && (
                  <div className="form-floating mb-3">
                    <select
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      className="form-select"
                      id="status"
                    >
                      <option value="pending">Pendiente</option>
                      <option value="released">Liberado</option>
                    </select>
                    <label htmlFor="status">Estatus</label>
                  </div>
                )}
                {selectedSubgroup && (
                  <div className="form-floating mb-3">
                    <textarea
                      type="text"
                      name="details"
                      value={formData.details}
                      onChange={handleChange}
                      className="form-control"
                      id="details"
                      placeholder="Detalles"
                    ></textarea>
                    <label htmlFor="details">Detalles</label>
                  </div>
                )}
              </div>

              {selectedSubgroup && (

                <div className='col-md-4'>
                  <div>
                    <RenderDropzone
                      onFilesChange={handleFileChange}
                      fileName='Certificado'
                      category="pdf_certificates"
                      files={formData.pdf_certificates}
                    />
                    <RenderDropzone
                      onFilesChange={handleFileChange}
                      fileName='Credenciales'
                      category="pdf_digital_credentials"
                      files={formData.pdf_digital_credentials}
                    />
                    <RenderDropzone
                      onFilesChange={handleFileChange}
                      fileName='Carta de Antigüedad'
                      category="pdf_letter_of_antiquity"
                      files={formData.pdf_letter_of_antiquity}
                    />
                    <RenderDropzone
                      onFilesChange={handleFileChange}
                      fileName='Otros'
                      category="pdf_others"
                      files={formData.pdf_others}
                    />
                  </div>
                </div>

              )}
              {selectedSubgroup && selectedPolicy && selectedHolder && (
                <div>
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      id="add_insured"
                      name="add_insured"
                      checked={addInsured}
                      onChange={handleAddInsuredChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="add_insured">
                      Añadir Titular
                    </label>
                  </div>
                  {addInsured && (
                    <div>
                      <h5>Datos Requeridos</h5>
                      <div className="mb-3">
                        <div className="row">

                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="text"
                                id="last_name_paternal"
                                name="last_name_paternal"
                                value={formData.last_name_paternal}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Apellido Paterno"
                                required

                              />
                              <label htmlFor="last_name_paternal">Apellido Paterno</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="text"
                                id="last_name_maternal"
                                name="last_name_maternal"
                                value={formData.last_name_maternal}
                                onChange={handleChange}
                                className="form-control"
                                required
                                placeholder="Apellido Materno"
                              />
                              <label htmlFor="last_name_maternal">Apellido Materno</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Primer nombre"
                                required
                              />
                              <label htmlFor="first_name">Primer nombre</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                name="relationship"
                                value={'Titular'}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Relación"
                                disabled
                              >
                              </input>
                              <label htmlFor="relationship" className="form-label">
                                Relación
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="date"
                                id="date_birth"
                                name="date_birth"
                                value={formData.date_birth}
                                onChange={handleChange}
                                className="form-control"
                                required
                                placeholder="Fecha de Nacimiento"
                              />
                              <label htmlFor="date_birth">Fecha de Nacimiento</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <select
                                id="gender"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                required
                                className="form-control"
                              >
                                <option value="">Seleccionar Género</option>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                              </select>
                              <label htmlFor="gender">Género</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="date"
                                id="current_antiquity"
                                name="current_antiquity"
                                value={formData.current_antiquity}
                                onChange={handleChange}
                                className="form-control"
                                required
                                placeholder="Antigüedad Actual"
                              />
                              <label htmlFor="current_antiquity">Antigüedad Actual</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-floating mb-2">
                              <input
                                type="date"
                                id="total_antiquity"
                                name="total_antiquity"
                                value={formData.total_antiquity}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Antigüedad Total"
                                required
                              />
                              <label htmlFor="total_antiquity">Antigüedad Total</label>
                            </div>
                          </div>

                        </div>
                      </div>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Extras</Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="email"
                                    id="work_email"
                                    name="work_email"
                                    value={formData.work_email}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Email de Trabajo"
                                  />
                                  <label htmlFor="work_email">Email de Trabajo</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  <label htmlFor="email">Email</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id="rfc"
                                    name="rfc"
                                    value={formData.rfc}
                                    onChange={handleChange}
                                    className="form-control text-uppercase"
                                    placeholder="RFC"
                                  />
                                  <label htmlFor="rfc">RFC</label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id="curp"
                                    name="curp"
                                    value={formData.curp}
                                    onChange={handleChange}
                                    className="form-control text-uppercase"
                                    placeholder="CURP"
                                  />
                                  <label htmlFor="curp">CURP</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id="phone_office"
                                    name="phone_office"
                                    value={formData.phone_office}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Teléfono de Oficina"
                                  />
                                  <label htmlFor="phone_office">Teléfono de Oficina</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id="phone_personal"
                                    name="phone_personal"
                                    value={formData.phone_personal}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Teléfono Personal"
                                  />
                                  <label htmlFor="phone_personal">Teléfono Personal</label>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {selectedSubgroup && (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            )}
            <button type="button" className="btn btn-danger" onClick={() => closeModal()}>
              Cancelar
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>



  );
}

export default CertificatesForm;
