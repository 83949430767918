import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRole } = userSlice.actions;
export const usersReducer = userSlice.reducer;

export default userSlice.reducer;
