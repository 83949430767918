import axios from 'axios';
import { apiUrl } from '../global'

// Este archivo contiene los métodos para interactuar con la API de aseguradoras
const token = localStorage.getItem('token');

// Método para obtener todas las aseguradoras
export const getUsers = async () => {
  console.log("Entrando a getUsers");
  try {
    const response = await axios.get(`${apiUrl}/users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      }
    });
    console.log("Repuesta del getUsers:", response.data)
    
    return response.data;
  } catch (error) {
    console.error('Error fetching Users:', error);
    throw error;
  }
};

// Método para agregar una nueva aseguradora
export const PostUsers = async (usersData) => {
  try {
    const response = await axios.post(`${apiUrl}/register`, usersData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding users:', error);
    throw error;
  }
};

// Método para actualizar una aseguradora existente
export const updateUsers = async (userId, userData) => {
  try {
    const response = await axios.patch(`${apiUrl}/insurer/${userId}`, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    });
    console.log("Respuesta de updateUsers:", response);
    return response.data;
    
  } catch (error) {
    console.error('Error updating users:', error);
    throw error;
  }
};
// Método para agregar una nueva aseguradora
export const Login = async (email,password) => {
  try {
    console.log("Entrando a login");
    const response = await axios.post(`${apiUrl}/login-admin`, {
      email,
      password,
    });
    const token = response.data.token; // Suponiendo que la API devuelve un token de autenticación
    localStorage.setItem('token', token); // Almacena el token en el localStorage

    return response.data.token;
  } catch (error) {
    console.error('Error adding users:', error);
    throw error;
  }
};

export const changeRole = async (email, role) => {
  try {
    console.log("cambiando ROLE");
    const response = await axios.post(`${apiUrl}/change-role`, {
      email,
      role
    });
    console.log("role cambiado: " ,response)    
    return response.data;
  } catch (error) {
    console.error('Error adding users:', error);
    throw error;
  }
};



export const getRole = async () => {
  console.log("Obteniendo ROLE");
  try {
    const response = await axios.get(`${apiUrl}/user-role`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Obtener token del localStorage
      }
    });
    console.log("Respuesta del getRole:", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Error 401: Unauthorized. Borrando el token.');
      localStorage.removeItem('token'); // Borrar token del localStorage
      // Opcional: Redireccionar al usuario a la página de login o mostrar un mensaje
      window.location.reload(); // Recargar la página para aplicar el estado actualizado
    } else {
      console.error('Error fetching Users:', error);
    }
    throw error;
  }
};
export const registerUserManual = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/createUserManual`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del registeruserManual",response.data)
    return response.data;
  } catch (error) {
    console.error('Error adding users:', error);
    throw error;
  }
};