import React, { useState, useEffect } from 'react';
import { getOrganizations } from '../api/OrganizationService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { postContacts } from '../api/ContactsService';

import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';

const MySwal = withReactContent(Swal);
function ContactForm({ updateList, organizationForeign }) {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organizations.data);
  const user = useSelector((state) => state.users.data);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [botonAdd, setBotonAdd] = useState(false);
  const [formData, setFormData] = useState({ organization_id: '', name: '', position: '', organization_id: '', phone_number: '', email: '', departament: '' });
  const [orgDatas, setOrgDatas] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    console.log('organizationForeign', organizationForeign)
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization)
    }
    if (!user.length) {
      infoGlobal.fetchRole();
    }
    if (organizationForeign) {
      console.log("organizationForeign", organizationForeign)
      setFormData((prevState) => ({
        ...prevState,
        organization_id: organizationForeign,
      }));
    }
  }, [organization, user, dispatch, organizationForeign]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await postContacts(formData);
      setBotonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
      setFormData({ name: '', position: '', phone_number: '', email: '', departament: '' }); // Limpia los datos del formulario
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El contacto se ha añadido correctamente.',
      });
      updateList();
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        title: 'Error',
        text: 'Hubo un problema al añadir el contacto. Inténtalo de nuevo.',
      });
    }
  };


  const handleOrganizationChange = async (selectedOrgId) => {
    try {
      setSelectedOrganization(selectedOrgId);
    } catch (error) {
      console.error(error)
    }
  };
  if (!botonAdd) {
    return (
      <div>
        {!botonAdd && (
          <div>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setBotonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <div className="row mb-3">
        <div className="col-md-4">
          <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre" />
        </div>
        <div className="col-md-4">
          <input type="text" name="position" value={formData.position} onChange={handleChange} className="form-control" placeholder="Posición" />
        </div>
        <div className="col-md-4">
          <input type="text" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control" placeholder="Número de Teléfono" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" placeholder="Correo" />
        </div>
        <div className="col-md-4">
          <input type="text" name="departament" value={formData.departament} onChange={handleChange} className="form-control" placeholder="Departamento" />
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Guardar</button>
      <button type="button" className="btn btn-danger" onClick={() => setBotonAdd(false)}>Cancelar</button>
    </form>
  );
}

export default ContactForm;
