import React, { useState, useEffect } from 'react';
import { getExecutive, updateExecutive, deleteExecutive } from '../../src/api/executiveService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ExecutiveForm from './ExecutiveForm';

const MySwal = withReactContent(Swal);

function ExecutivesList() {
  const [executives, setexecutives] = useState([]); // Estado para almacenar los datos de los ejecutivos

  const [editingId, setEditingId] = useState(null);
  const [originalData, setOriginalData] = useState({});
  const [updatedData, setUpdatedData] = useState({});

  const handleEdit = (id) => {
    setEditingId(id);
    const executiveToEdit = executives.find(executive => executive.id_executive === id);
    setOriginalData(executiveToEdit); // Al iniciar la edición, guarda los datos originales del seguro
    setUpdatedData(executiveToEdit);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleSaveEdit = async (id) => {
    setEditingId(null);
    try {
      // Filtrar solo los campos modificados comparando los datos actualizados con los datos originales
      const changedFields = Object.keys(updatedData).filter(key => updatedData[key] !== originalData[key]);
      // Si no hay campos modificados, no hay necesidad de enviar una solicitud de actualización
      if (changedFields.length === 0) {
        console.log('No se han realizado cambios.');
        return;
      }
      const updatedFields = {};
      // Crear un objeto solo con los campos modificados
      changedFields.forEach(key => {
        updatedFields[key] = updatedData[key];
      });
      console.log("Datos nuevos:", updatedFields);
      // Llamar a la función de actualización de la API con los datos modificados
      await updateExecutive(id, updatedFields);
      // Restablecer los datos originales y los datos actualizados después de guardar los cambios
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(() => {
        fetchExecutives();
      });
    } catch (error) {
      console.error('Error updating executives:', error);
      // Manejar cualquier error que ocurra durante la actualización
      // Restablecer los datos originales y los datos actualizados en caso de error
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };
  const handleUpdateExecutiveList = async () => {
    await fetchExecutives();
  };
  const handleDeleteExecutive = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar este ejecutivo?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteExecutive(id);
          // Filtrar la lista de ejecutivos para eliminar solo el ejecutivo con el ID correspondiente
          setexecutives(prevExecutives => prevExecutives.filter(executive => executive.id_executive !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El ejecutivo se ha eliminado correctamente.',
          });
          fetchExecutives();
        } catch (error) {
          console.error('Error deleting executive:', error);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al eliminar el ejecutivo.',
          });
        }
      }
    });
  };

  const fetchExecutives = async () => {
    try {
      const data = await getExecutive(); // Obtiene los datos de las aseguradoras
      setexecutives(data.executives); // Actualiza el estado con los datos obtenidos
    } catch (error) {
      console.error('Error fetching executives:', error);
    }
  };
  useEffect(() => {
    fetchExecutives();
  }, []);
  const handleFieldChange = (fieldName, value) => {
    setUpdatedData({ ...updatedData, [fieldName]: value });
  };

  return (
    <div>
      <ExecutiveForm updateExecutiveList={handleUpdateExecutiveList} />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Foto</th>
            <th scope="col">Nombre</th>
            <th scope="col">Departamento</th>
            <th scope="col">Posicion</th>
            <th scope="col">Email</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Telefono whatsapp</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {executives.map((executive) => (
            <tr key={executive.id_executive}>
              <td><img src={executive.photo_link} alt={executive.name} style={{ width: '50px', marginRight: '10px' }} /></td>
              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.name} onChange={(e) => handleFieldChange('name', e.target.value)} /> : executive.name}</td>

              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.department} onChange={(e) => handleFieldChange('department', e.target.value)} /> : executive.department}</td>

              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.position} onChange={(e) => handleFieldChange('position', e.target.value)} /> : executive.position}</td>

              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.email} onChange={(e) => handleFieldChange('email', e.target.value)} /> : executive.email}</td>

              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.phone} onChange={(e) => handleFieldChange('phone', e.target.value)} /> : executive.phone}</td>

              <td>{editingId === executive.id_executive ? <input type="text" defaultValue={executive.whatsapp} onChange={(e) => handleFieldChange('whatsapp', e.target.value)} /> : executive.whatsapp}</td>
              <td>
                {editingId === executive.id_executive ? (
                  <>
                    <button className="btn btn-success btn-sm me-1" onClick={() => handleSaveEdit(executive.id_executive)}>Guardar</button>
                    <button className="btn btn-secondary btn-sm" onClick={handleCancelEdit}>Cancelar</button>
                  </>
                ) : (
                  <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(executive.id_executive)}>Editar</button>
                )}
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteExecutive(executive.id_executive)}>Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ExecutivesList
