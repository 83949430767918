import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


export const sendEmailCertificate = async (id_certificate, data) => {
    try {
        console.log("sendCertificate, Datos: ", data);

        const response = await axios.post(`${apiUrl}/email/send-certificate/${id_certificate}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        console.log(`La informacion a mandar: ${JSON.stringify(data)}`);
        console.log("Response from server:", response.data);

        return response.data;
    } catch (error) {
        console.error('Error adding certificate:', error);
        throw error;
    }
};
export const sendInvitation = async (id_certificate, data) => {
    try {
        console.log("postCertificate, Datos: ", data);

        const response = await axios.post(`${apiUrl}/email/send-invitation/${id_certificate}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log(`La informacion a mandar: ${JSON.stringify(data)}`);
        console.log("Response from server:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error adding certificate:', error);
        throw error;
    }
};