import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');

// Método para obtener la poliza dado el numero de poliza
export const getProcedures = async () => {
    console.log("Entrando a getProcedures");
    try {
        const response = await axios.get(`${apiUrl}/procedures/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Respuesta del getProcedures", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching getProcedures:', error);
        throw error;
    }
};
export const getProceduresByPolicy = async (id_policy) => {
    console.log("Entrando a getProceduresByPolicy");
    try {
        const response = await axios.get(`${apiUrl}/getProceduresByPolicy/${id_policy}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Respuesta del getProceduresByPolicy", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching getProceduresByPolicy:', error);
        throw error;
    }
};
export const getProceduresByPolicyHolder = async (policy_holder_id) => {
    console.log("Entrando a getProcedures");
    try {
        const response = await axios.get(`${apiUrl}/getProceduresByPolicyHolder/${policy_holder_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Respuesta del getProceduresByPolicyHolder", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching getProceduresByPolicyHolder:', error);
        throw error;
    }
};
export const getOldProcedures = async () => {
    console.log("Entrando a getOldProcedures");
    try {
        const response = await axios.get(`${apiUrl}/procedures/old`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Respuesta del getOldProcedures", response.data.procedures);
        return response.data.procedures;
    } catch (error) {
        console.error('Error fetching getOldProcedures:', error);
        throw error;
    }
};

export const getFinalizaedProcedures = async (daysAgo) => {
    console.log("Entrando a getFinalizaedProcedures");
    try {
        const response = await axios.get(`${apiUrl}/procedures/finalized/${daysAgo}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Respuesta del getFinalizaedProcedures", response.data.procedures);
        return response.data.procedures;
    } catch (error) {
        console.error('Error fetching getFinalizaedProcedures:', error);
        throw error;
    }
};

export const postProcedure = async (data) => {
    try {
        console.log("postProcedure, Datos: ", data);

        const response = await axios.post(`${apiUrl}/procedure`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log(`La informacion a mandar: ${data}`)
        console.log("Response postProcedure:", response.data);

        return response.data;
    } catch (error) {
        console.error('Error adding Procedure:', error);
        throw error;
    }
};
export const updateProcedure = async (id, data) => {
    console.log("data",data)
    console.log(id)
    try {
        const response = await axios.put(`${apiUrl}/procedure/${id}`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
      console.log("Respuesta updateProcedure :", response);
      return response.data;
  
    } catch (error) {
      console.error('Error updating procedure:', error);
      throw error;
    }
  };