import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { postCertificateMassive } from '../../api/MassiveDataService';
import { useDropzone } from 'react-dropzone';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

// Incluye SweetAlert2 y otros módulos necesarios
const MySwal = withReactContent(Swal);

// Definir el estilo CSS dentro del JavaScript
const customCSS = `
    .custom-swal-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }
    .custom-swal-popup {
        width: 1450px;
        max-width: 2000px; /* Ajusta el ancho máximo según tus necesidades */
        margin: 0 auto; /* Centra el popup horizontalmente */
        font-size: 15px; /* Tamaño de la fuente */
    }
    .custom-swal-title {
        font-size: 40px; /* Tamaño de la fuente del título */
    }
`;

// Crear un elemento <style> y agregarlo al <head> del documento
const styleElement = document.createElement('style');
styleElement.innerHTML = customCSS;
document.head.appendChild(styleElement);

const ExcelUpload = ({ policy_id }) => {
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState([]);
    const [policyId, setPolicyId] = useState(policy_id); // Inicializar con el prop
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(''); // Nuevo estado para el nombre del archivo

    useEffect(() => {
        setPolicyId(policy_id); // Actualizar cuando el prop cambie
    }, [policy_id]); // Dependencia en el prop

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        maxFiles: 1, // Permitir solo un archivo
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                setFileName(selectedFile.name); // Guardar el nombre del archivo
                handleFileUpload({ target: { files: acceptedFiles } });
            }
        },
    });

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheet = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheet];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 0) {
                const headerRow = jsonData[0];
                const dataRows = jsonData.slice(1);
                setHeaders(headerRow);

                const mappedData = dataRows
                    .map(row => headerRow.reduce((acc, header, index) => {
                        acc[header] = row[index] || '';
                        return acc;
                    }, {}))
                    .filter(row => Object.values(row).some(value => value.trim() !== ''));

                setData(mappedData);
            }
        };
        reader.readAsBinaryString(selectedFile);
        console.log("Archivo subido");
    };

    const handleSubmit = async () => {
        if (!policyId || !file) {
            if (!policyId) {
                alert('No hay Poliza seleccionada');
                return;
            }
            else {
                alert('No hay archivo seleccionado');
                return;
            }
        }

        try {
            // Crear una tabla HTML con los datos
            const tableHTML = `
                <div style="max-height: 300px; overflow-y: auto;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Subgrupo</th>
                                <th>Nombre Sub</th>
                                <th>Nivel Hosp.</th>
                                <th>Certificado</th>
                                <th>Nombre Completo</th>
                                <th>Relación</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${data.map(item => `
                                <tr>
                                    <td>${item['subgroup_number'] || ''}</td>
                                    <td>${item['subgroup_name'] || 'NA'}</td>
                                    <td>${item['hospital_level'] || 'NA'}</td>
                                    <td>${item['certificate_number'] || ''}</td>
                                    <td>${item['first_name'] || ''} ${item['last_name_paternal'] || ''} ${item['last_name_maternal'] || ''}</td>
                                    <td>${item['relationship'] || ''}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>
            `;

            await MySwal.fire({
                icon: 'question',
                title: 'Confirmar Envío',
                html: `
                    <p>Se van a enviar los siguientes datos, ¿desea continuar?</p>
                    ${tableHTML}
                `,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí, Enviar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    container: 'custom-swal-container',
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title'
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // Enviar los datos
                        const response = await postCertificateMassive(policyId, data);

                        // Confirmación de éxito
                        await MySwal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Los datos han sido enviados correctamente.',
                            confirmButtonText: 'OK'
                        });

                    } catch (error) {
                        // Manejo de error en el envío de datos
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: `<pre>${error.message || 'Error desconocido'}</pre>`,
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonText: 'OK'
                        });
                    }
                }
            });

        } catch (error) {
            await MySwal.fire({
                icon: 'error',
                title: 'Error',
                html: `<pre>${error.message || 'Error desconocido'}</pre>`,
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className="mt-4">
            <div
                {...getRootProps({
                    className: `dropzone border rounded p-4 ${isDragActive ? 'bg-light' : 'bg-white'
                        }`
                })}
            >
                <input {...getInputProps()} />
                <p className="text-center">
                    {isDragActive
                        ? 'Suelta el archivo aquí...'
                        : 'Suelta el archivo Excel o da clic para seleccionar'}
                </p>
                {fileName && <p className="text-center mt-2">Archivo seleccionado: <strong>{fileName}</strong></p>}
            </div>
            <div className="mt-3">
                <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                >
                    Subir
                </button>
            </div>
        </div>
    );
};

export default ExcelUpload;
