import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todos los certificados de un subgrupo
export const getCertificatesBySubgroup = async (id_subgroup) => {
  console.log("Entrando a getSubgroup");
  try {
    const response = await axios.get(`${apiUrl}/subgroups/${id_subgroup}/certificates`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getSubgroup:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching getSubgroup:', error);
    throw error;
  }
};
// Ruta para mostrar los subgrupos de una póliza por su ID

export const getSubgroupByPolicyId = async (id_policy) => {
  console.log("Entrando a getSubgroupByPolicyId");
  try {
    const response = await axios.get(`${apiUrl}/getSubgroupsById/${id_policy}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del get:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching getSubgroupByPolicyId:', error);
    throw error;
  }
};

// Método para agregar un nuevo Contratante
export const postCertificate = async (data) => {
  try {
    console.log("postCertificate, Datos: ", data);

    const response = await axios.post(`${apiUrl}/certificate`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${data}`)
    console.log("Response from server:", response);

    return response.data;
  } catch (error) {
    console.error('Error adding certificate:', error);
    throw error;
  }
};
export const sendCertificate = async (id, data) => {
  console.log('Data to send:', data);
  
  try {
    const response = await axios.post(`${apiUrl}/send-certificate/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Certificate sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending certificate:', error);
    throw error;
  }
};
export const sendCertificateWithId = async (id) => {
  console.log('Data to send:', id);
  
  try {
    const response = await axios.post(`${apiUrl}/sendCertificateWithId/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Certificate sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending certificate:', error);
    throw error;
  }
};

export const sendToAdminPolicy = async (id, email) => {
  try {
    const response = await axios.post(`${apiUrl}/send-to-admin/${id}`, { admin_email: email }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Certificate sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending certificate:', error);
    throw error;
  }
};


// Método para actualizar existente
export const updateCertificate = async (id, data) => {
  console.log("Entrando a updateCertificate")
  console.log("DAtos enviados", data)
  try {
    const response = await axios.put(`${apiUrl}/certificates/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating subgroup:', error);
    throw error;
  }
};
export const deleteCertificate = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/certificates/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting subgroup:', error);
    throw error;
  }
};
export const uploadCertificate = async (id, file) => {
  console.log("datos", file);
  const formData = new FormData();
  formData.append('pdf', file); // Asegúrate de que 'file' sea el archivo PDF

  try {
    const response = await axios.post(`${apiUrl}/uploadCertificate/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del uploadCertificate:", response.data);
    return response.data; // Devuelve solo los datos de la respuesta, no todo el objeto response

  } catch (error) {
    console.error('Error uploadCertificate PDF:', error);
    throw error;
  }
};
//Servicio para obtener certificados pendientes ordenados por los más viejos:

export const getCertificatesPendingOrderOld = async () => {
  try {
    const response = await axios.get(`${apiUrl}/certificates/pending/oldest`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching certificates pending order old:', error);
    throw error;
  }
};
//Servicio para obtener certificados con el estado "released" de los últimos 10 días:
export const getCertificatesReleasedLast7Days = async () => {
  try {
    const response = await axios.get(`${apiUrl}/certificates/released/last7days`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching certificates released last 10 days:', error);
    throw error;
  }
};
//Servicio para obtener todos los certificados ordenados por updated_at de los últimos 10 días:
export const getAllCertificatesUpdatedLast10Days = async () => {
  try {
    const response = await axios.get(`${apiUrl}/certificates/updated/last10days`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all certificates updated last 10 days:', error);
    throw error;
  }
};
//Servicio para obtener todos los certificados dando el id de una poliza
export const getCertificatesByPolicyId = async (id) => {
  console.log("Entrando a getCertificatesByPolicyId");
  try {
    const response = await axios.get(`${apiUrl}/getCertificatesByPolicyId/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("data de getCertificatesByPolicyId", response.data);
    return response.data;
  } catch (error) {
    console.error('Error getCertificatesByPolicyId:', error);
    throw error;
  }
};
// Método para actualizar el estatus de un certificado existente
export const updateStatusCertificate = async (id, data) => {
  console.log("Entrando a updateStatusCertificate")
  console.log("DAtos enviados", data)
  try {
    const response = await axios.patch(`${apiUrl}/updateStatusCertificate/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta updateStatusCertificate:", response);
    return response.data;

  } catch (error) {
    console.error('Error updateStatusCertificate:', error);
    throw error;
  }
};
