import React, { useState } from 'react';
import { postInsurance, updateInsurance } from '../api/insurerService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function InsurersForm({ updateInsuranceList }) {
  const [buttonAdd, setButtonAdd] = useState(false);
  const [formData, setFormData] = useState({
    com_id_insurer: '',
    logo_path: '',
    commercial_name: '',
    fiscal_address: '',
    general_conditions: '',
    legal_name: '',
    phone_claims: '',
    phone_service: '',
    rfc: '',
    website: '',
    agent_key: '',
  });

  const uniqueId = () => {
    return Math.floor(Math.random() * Date.now());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convertir a mayúsculas solo para campos específicos
    const upperCaseFields = ['legal_name', 'rfc'];
    const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;

    // Actualizar el estado
    setFormData({ ...formData, [name]: newValue });
  };

  const handleFileChange = (event) => { // Cambio de nombre de la función
    const randomId = uniqueId();
    const files = event.target.files;
    const filesArray = Array.from(files);
    setFormData({ ...formData, pdfs: filesArray }); // Cambio de nombre a 'pdfs'
  };
  const handleImageChange = (event) => {
    console.log("img", event.target.files[0])
    const randomId = uniqueId();

    setFormData({ ...formData, logo_path: event.target.files[0], com_id_insurer: 'Insure_' + randomId });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await postInsurance(formData);
      setFormData({
        com_id_insurer: '',
        pdfs: [],
        logo_path: '',
        commercial_name: '',
        fiscal_address: '',
        general_conditions: '',
        legal_name: '',
        phone_claims: '',
        phone_service: '',
        rfc: '',
        website: ''
      });
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'La aseguradora se ha añadido correctamente.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        text: 'Hubo un problema al añadir la aseguradora. Inténtalo de nuevo.',
      });
    }
  };



  if (!buttonAdd) {
    return (
      <div>
        {!buttonAdd && (
          <div>
            <h2> Aseguradoras</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success" onClick={() => setButtonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <h2>Aseguradoras</h2>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="mb-3">
            <label htmlFor="logo_path">Logo de la Aseguradora</label>

            <input
              type="file"
              id="logo_path"
              name="logo_path"
              accept="image/*"
              onChange={handleImageChange}
              className="form-control"
            />
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="commercial_name"
              value={formData.commercial_name}
              onChange={handleChange}
              className="form-control"
              id="floatingCommercialName"
              placeholder=" "
            />
            <label htmlFor="floatingCommercialName">Nombre comercial</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="agent_key"
              value={formData.agent_key}
              onChange={handleChange}
              className="form-control"
              id="floatingAgentKey"
              placeholder=" "
            />
            <label htmlFor="floatingAgentKey">Clave de Agente</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating mb-3">
            <input
              type="text"
              name="general_conditions"
              value={formData.general_conditions}
              onChange={handleChange}
              className="form-control"
              id="floatingGeneralConditions"
              placeholder=" "
            />
            <label htmlFor="floatingGeneralConditions">Condiciones Generales</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="legal_name"
              value={formData.legal_name}
              onChange={handleChange}
              className="form-control text-uppercase" // Clase para mayúsculas
              id="floatingLegalName"
              placeholder=" "
            />
            <label htmlFor="floatingLegalName">Nombre Legal</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="rfc"
              value={formData.rfc}
              onChange={handleChange}
              className="form-control text-uppercase"
              id="floatingRFC"
              placeholder=" "
            />
            <label htmlFor="floatingRFC">RFC</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating mb-3">
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="form-control"
              id="floatingWebsite"
              placeholder=" "
            />
            <label htmlFor="floatingWebsite">Página Web</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="phone_claims"
              value={formData.phone_claims}
              onChange={handleChange}
              className="form-control"
              id="floatingPhoneClaims"
              placeholder=" "
            />
            <label htmlFor="floatingPhoneClaims">Teléfono de reclamaciones</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="phone_service"
              value={formData.phone_service}
              onChange={handleChange}
              className="form-control"
              id="floatingPhoneService"
              placeholder=" "
            />
            <label htmlFor="floatingPhoneService">Teléfono de servicio</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="fiscal_address"
              value={formData.fiscal_address}
              onChange={handleChange}
              className="form-control"
              id="floatingFiscalAddress"
              placeholder=" "
            />
            <label htmlFor="floatingFiscalAddress">Dirección</label>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="pdf_files" className="form-label">Archivos PDF</label>
        <input
          type="file"
          id="pdf_files"
          name="pdf_files"
          accept="application/pdf"
          multiple
          onChange={handleFileChange}
          className="form-control"
        />
      </div>

      <button type="submit" className="btn btn-primary me-3">Submit</button>
      <button className="btn btn-danger" onClick={() => setButtonAdd(false)}>Cancelar</button>
    </form>
  );

}

export default InsurersForm;
