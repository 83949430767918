import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');
export const postCertificateMassive = async (id, data) => {
    console.log("Entrando a postCertificateMassive: ", data);
  
    try {
      const response = await axios.post(`${apiUrl}/certificate/massive/${id}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
      });
      console.log("Repuesta del postCertificateMassive:", response.data)
  
      return response.data;
    } catch (error) {
      console.error('Error postCertificateMassive:', error);    
      console.log("Repuesta del postCertificateMassive Error:", data)
      throw error;
    }
  };