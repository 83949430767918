import axios from 'axios';
import { apiUrl } from '../../global'

const token = localStorage.getItem('token');


// Método para obtener todas los ejecutivos
export const getPools = async () => {
  console.log("Entrando a getPools");
  try {
    const response = await axios.get(`${apiUrl}/pools`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getPools:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching Pools:', error);
    throw error;
  }
};

// Método para agregar una nueva foto para el carrusel
export const postPools = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/pools`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${data}`)
    return response.data;
  } catch (error) {
    console.error('Error adding Pools:', error);
    throw error;
  }
};
