import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los Contratantes
export const getHolders = async () => {
  console.log("Entrando a getHolder");
  try {
    const response = await axios.get(`${apiUrl}/policyholders`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getHolder:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching policyholders:', error);
    throw error;
  }
};
// Método para obtener todas los Contratantes dando el id de la Organizacion
export const getHolderByOrganization = async (id_organization) => {
  console.log("Entrando a getHolderByOrganization");
  try {
    const response = await axios.get(`${apiUrl}/policyholders/${id_organization}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getHolderByOrganization:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching policyholders:', error);
    throw error;
  }
};
// Método para agregar un nuevo Contratante

export const postHolder = async (data) => {
  try {
    console.log("postHolders, Datos: ", data);

    const response = await axios.post(`${apiUrl}/policyholder`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`Respuesta: ${response.data}`)
    return response.data;
  } catch (error) {
    console.error('Error adding policyholder:', error);
    throw error;
  }
};
// Método para actualizar ejecutivo existente
export const updateHolder = async (id, data) => {
  try {
    const response = await axios.patch(`${apiUrl}/policyholder/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating policyholder:', error);
    throw error;
  }
};
export const deleteHolder = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/policyholder/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting policyholder:', error);
    throw error;
  }
};
export const getPoliciesByHolder = async (policy_holder_id) => {
  try {
    const response = await axios.get(`${apiUrl}/policies/findByHolder/${policy_holder_id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Respuesta de getPoliciesByHolder",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching policy:', error);
    throw error;
  }
};