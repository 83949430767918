import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');
// Método para obtener todas las aseguradoras
export const getLogs = async (id, table = null, daysAgo = null) => {
  console.log("Entrando a getLogs");

  // Construir la URL con parámetros opcionales
  let url = `${apiUrl}/logs/${id}`;
  if (table) {
    url += `/${table}`;
  }
  if (daysAgo) {
    url += `/${daysAgo}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Respuesta del getLogs:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching getLogs:', error);
    throw error;
  }
};