import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Spinner, Alert } from 'react-bootstrap'; import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getLogs } from '../../../api/LogsServices';


const MySwal = withReactContent(Swal);

// Función para formatear las fechas
const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : 'NA';
};

const ModalChangesProcedures = ({ show, onHide, data }) => {
    const [changes, setChanges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log("DATAAAAAAAAAAAAAAAA", data);

    useEffect(() => {
        fetchData()
    }, []);
    const fetchData = async () => {
        const response = await getLogs(data.id_procedure, 'procedures')
        setChanges(response)
    };
    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Listado de Cambios</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Campo Cambiado</th>
                            <th>Valor Antiguo</th>
                            <th>Nuevo Valor</th>
                            <th>Tipo de Operación</th>
                            <th>Id del user</th>
                            <th>Fecha de Cambio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {changes.map((change, index) => (
                            <tr key={index}>
                                <td>{change.field_changed ? change.field_changed : "NA"}</td>
                                <td>{change.old_value ? change.old_value : "NA"}</td>
                                <td>{change.new_value ? change.new_value : "NA"}</td>
                                <td>{change.operation_type ? change.operation_type : "NA"}</td>
                                <td>{change.user_id ? change.user_id : "NA"}</td>
                                <td>{change.changed_at ? formatDate(change.changed_at) : "NA"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalChangesProcedures;