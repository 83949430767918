import React, { useState, useEffect } from 'react';
import { getHolders, updateHolder, deleteHolder } from '../api/PolicyHolderService';
import { getPoliciesByHolder } from '../api/policyService';
import { getSubgroupByPolicyId } from "../api/SubgroupService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PolicyHolderForm from './PolicyHolderForm';
import SubgroupForm from './SubgroupForm';
import { getOrganizations } from '../api/OrganizationService';
import { getContactsByOrganizationId } from '../api/ContactsService';
import ContactForm from './ContactForm';
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';

const MySwal = withReactContent(Swal);

function ContactList() {
    const [holders, setHolders] = useState([]); // Estado para almacenar los contratantes
    const [policies, setPolicies] = useState([]); // Estado para almacenar las pólizas
    const [selectedOrganization, setSelectedOrganization] = useState(''); // Estado para la organización seleccionada
    const [organizations, setOrganizations] = useState([]); // Inicializado como array vacío
    const [contacts, setContacts] = useState([]); // Inicializado como array vacío
    const [editingId, setEditingId] = useState(null);
    const [originalData, setOriginalData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [dataFetched, setDataFetched] = useState(false);
  const handleEdit = (id) => {
    setEditingId(id);
    const organizationToEdit = contacts.find(holder => holder.contact === id);
    setOriginalData(organizationToEdit); // Al iniciar la edición, guarda los datos originales 
    setUpdatedData(organizationToEdit);
  };


  const handleCancelEdit = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleSaveEdit = async (id) => {
    setEditingId(null);
    try {
      const changedFields = Object.keys(updatedData).filter(key => updatedData[key] !== originalData[key]);
      if (changedFields.length === 0) {
        console.log('No se han realizado cambios.');
        return;
      }
      const updatedFields = {};
      changedFields.forEach(key => {
        updatedFields[key] = updatedData[key];
      });
      console.log("Datos nuevos:", updatedFields);
      await updateHolder(id, updatedFields);
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(() => {
        fetchContacts();
      });
    } catch (error) {
      console.error('Error updating subgroup:', error);
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        title: '¡Error!',
        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };

  const handleUpdateList = async () => {
    await fetchContacts();
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar este Contratante?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteHolder(id);
          setHolders(prevHolders => prevHolders.filter(holder => holder.id_policy_holder !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El Contacto se ha eliminado correctamente.',
          });
        } catch (error) {
          console.error('Error deleting contact:', error);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al eliminar el contacto.',
          });
        }
      }
    });
  };

  const fetchContacts = async () => {
    try {
      const data = await getContactsByOrganizationId(selectedOrganization); // Obtiene los datos 
      setContacts(data.contacts);
      setDataFetched(true)
    } catch (error) {
      console.error('Error fetching Org:', error);
    }
  };


  const handleFieldChange = (fieldName, value) => {
    setUpdatedData({ ...updatedData, [fieldName]: value });
  };

  const handleOrganizationChange = async (selectedOrgId) => {
    setSelectedOrganization(selectedOrgId);
    try {
      const contactData = await getContactsByOrganizationId(selectedOrgId);
      setContacts(contactData.contacts);
      setDataFetched(true)
    } catch (error) {
      console.error('Error fetching Contacts:', error);
    }
  };

  return (
    <div>
      <div className="w-100 px-3 mx-auto">
        <div className="row">
        <SearchOrganization onSelect={handleOrganizationChange}  />
        <ContactForm updateList={fetchContacts} organizationForeign={selectedOrganization} />
        </div>
      </div>
      {dataFetched && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Departamento</th>
              <th scope="col">Posicion</th>
              <th scope="col">Email</th>
              <th scope="col">Telefono</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id_contact}>
                <td>
                  {editingId === contact.id_contact ? (
                    <input
                      type="text"
                      defaultValue={contact.name}
                      onChange={(e) => handleFieldChange('name', e.target.value)}
                    />
                  ) : (
                    contact.name
                  )}
                </td>
                <td>
                  {editingId === contact.id_contact ? (
                    <input
                      type="text"
                      defaultValue={contact.departament}
                      onChange={(e) => handleFieldChange('departament', e.target.value)}
                    />
                  ) : (
                    contact.departament
                  )}
                </td>
                <td>
                  {editingId === contact.id_contact ? (
                    <input
                      type="text"
                      defaultValue={contact.position}
                      onChange={(e) => handleFieldChange('position', e.target.value)}
                    />
                  ) : (
                    contact.position
                  )}
                </td>
                <td>
                  {editingId === contact.id_contact ? (
                    <input
                      type="text"
                      defaultValue={contact.email}
                      onChange={(e) => handleFieldChange('email', e.target.value)}
                    />
                  ) : (
                    contact.email
                  )}
                </td>
                <td>
                  {editingId === contact.id_contact ? (
                    <input
                      type="text"
                      defaultValue={contact.phone_number}
                      onChange={(e) => handleFieldChange('phone_number', e.target.value)}
                    />
                  ) : (
                    contact.phone_number
                  )}
                </td>

                <td>
                  {editingId === contact.id_contact ? (
                    <>
                      <button className="btn btn-success btn-sm me-1" onClick={() => handleSaveEdit(contact.id_contact)}>Guardar</button>
                      <button className="btn btn-secondary btn-sm" onClick={handleCancelEdit}>Cancelar</button>
                    </>
                  ) : (
                    <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(contact.id_contact)}>Editar</button>
                  )}
                  <button className="btn btn-danger btn-sm" onClick={() => handleDelete(contact.id_contact)}>Borrar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

    </div>
  );
}

export default ContactList;
