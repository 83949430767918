import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Ruta para mostrar todos los contactos pertenecientes a una organización específica
export const getContactsByOrganizationId = async (organization_id) => {
  console.log("Entrando a getContactsByOrganizationId");
  try {
    const response = await axios.get(`${apiUrl}/organizations/${organization_id}/contacts`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del get:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

// Método para agregar un nuevo Contratante

export const postContacts = async (data) => {
  try {
    console.log("Postcontacts, Datos: ", data);

    const response = await axios.post(`${apiUrl}/contacts`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${data}`)
    return response.data;
  } catch (error) {
    console.error('Error adding contact:', error);
    throw error;
  }
};
// Método para actualizar contact existente
export const updateContact = async (id, data) => {
  try {
    const response = await axios.patch(`${apiUrl}/contacts/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating policyholder:', error);
    throw error;
  }
};
export const deleteHolder = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/contacts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting contacts:', error);
    throw error;
  }
};