// reducers/policyHolderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const poolsSlice = createSlice({
  name: 'pool',
  initialState: {
    data: [],
  },
  reducers: {
    setPools(state, action) {
      state.pools = action.payload;
    },
    addPools(state, action) {
      state.pools.push(action.payload);
    },
    
  },
});

export const { setPools, addPools } = poolsSlice.actions;

export const poolsReducer = poolsSlice.reducer;

export default poolsSlice.reducer;
