import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Table, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button as ButtonMui } from '@mui/material';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateStatusCertificate } from "../../../api/CertificateService";
import { openPdf } from '../../../features/pdfs/functionsPdf';
import { pdfLogo } from "../../../global";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../../../global';
import { updateProcedure } from '../../../api/ProceduresService';
import AddIcon from '@mui/icons-material/Add';

const MySwal = withReactContent(Swal);

const ModalActionsProcedures = ({ show, onHide, data }) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organizations.data);
  const insurers = useSelector((state) => state.insurer.data);
  const executives = useSelector((state) => state.executive.data);
  const [editForm, setEditForm] = React.useState(false);
  const [rows, setRows] = React.useState([{
    policy_number: '',
    endorsement_number: '',
    issue_date: new Date().toISOString().split('T')[0], // Fecha automática
    pdf: ''
  }]);
  const [formData, setFormData] = React.useState({
    insurer_folio: data?.insurer_folio || '',
    internal_folio: data?.internal_folio || '',
    procedure_type: data?.procedure_type || '',
    policy_branch: data?.policy_branch || '',
    details: data?.details || 'NA',
    request_method: data?.request_method || '',
    endorsement_number: data?.endorsement_number || '',
    policy_number: data?.policy?.policy_number || '',
    policy_type: data?.policy?.policy_type || '',
    insurer_name: data?.insurer?.commercial_name || '',
    phone_claims: data?.insurer?.phone_claims || '',
    executive_name: data?.executive?.name || '',
    expected_date: data?.expected_date || '',
    client_request_date: data?.client_request_date || '',
    insurer_process_start_date: data?.insurer_process_start_date || '',
    response_date: data?.response_date || '',
    procedure_completion_date: data?.procedure_completion_date || '',
    client_delivery_date: data?.client_delivery_date || '',
    movement_type: data?.movement_type || '',
    status: data?.status || '',
    organization: data?.organization || '',
    contact: data?.contact || '',
    policy_number: '',
    endorsement_number: '',
    issue_date: new Date().toISOString().split('T')[0], // Fecha automática
    pdf: '',
  });

  const [orgDatas, setOrgDatas] = useState([]);
  const [executivesDatas, setExecutives] = useState([]);
  const [insurersDatas, setInsurers] = useState([]);
  useEffect(() => {
    console.log("dataa", data)
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization);
    }
    if (!insurers.length) {
      infoGlobal.fetchInsurers();
    } else {
      setInsurers(insurers);
    }
    if (!executives.length) {
      infoGlobal.fetchExecutives();
    } else {
      setExecutives(executives);
    }
  }, [organization, dispatch, insurers, executives]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy', { locale: es });
  }

  if (!data) return null;

  const handleClose = () => {
    onHide();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRowChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        policy_number: '',
        endorsement_number: '',
        issue_date: new Date().toISOString().split('T')[0], // Fecha automática
        pdf: ''
      }
    ]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data.id_certificate)
    try {
      //await updateStatusCertificate(data.id_certificate, formData);
      await updateProcedure(data.id_procedure, formData)
      setEditForm(false)
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El certificado se ha actualizado correctamente.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.message || 'Error desconocido'}</pre>`,

        html: `Ha ocurrido un error al Actualizar el certificado.<br/><strong>${error.response.data.message}</strong>`,
      });
    }
  };
  const handleEdit = () => {
    if (editForm) {
      setEditForm(false)
    } else {
      setEditForm(true)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName='modal-xl' >

        <Modal.Header closeButton>
          <Modal.Title>Información del Tramite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
              <Col xs={8} >
                <div style={{ borderRight: '1px solid #ddd', paddingRight: '9px' }}>
                  <h4>Generales</h4>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="date"
                        name="client_request_date"
                        value={formData.client_request_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingClientRequestDate"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingClientRequestDate">Solicitud del Cliente</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="organization"
                        value={formData.organization.name || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingOrganization"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingOrganization">Organización</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="insurer_name"
                        value={formData.insurer_name || ''}
                        disabled={true}
                        className="form-control"
                        id="floatingInsurerName"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingInsurerName">Aseguradora</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <select
                        type="text"
                        name="policy_branch"
                        value={formData.policy_branch || ''}
                        disabled={!editForm}
                        onChange={handleChange}
                        className="form-select"
                        id="floatingPolicyBranch"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                      >
                        <option value="Vida">Vida</option>
                        <option value="Vida Grupo">Vida Grupo</option>
                        <option value="Gmm Individual">GMM Individual</option>
                        <option value="GMM Colectivo">GMM Colectivo</option>
                        <option value="Otro">Otro</option>
                      </select>
                      <label htmlFor="floatingPolicyBranch">Rama</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="contact"
                        value={formData.contact.name || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingContact"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingContact">Quien Solicita</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="request_method"
                        value={formData.request_method || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingRequestMethod"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingRequestMethod">Medio de Solicitud</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="insurer_folio"
                        value={formData.insurer_folio || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingInsurerFolio"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingInsurerFolio">TIPO DE Tramite</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="internal_folio"
                        value={formData.internal_folio || ''}
                        disabled={true}
                        className="form-control"
                        id="floatingInternalFolio"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingInternalFolio">Folio Interno</label>
                    </div>
                    <div style={{ width: '100%', borderBottom: '1px solid #ddd' }}>
                      <h3 style={{ margin: '0', textAlign: 'center' }}>Trámite</h3>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                      <input
                        type="date"
                        name="insurer_process_start_date"
                        value={formData.insurer_process_start_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingInsurerProcessStartDate"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingInsurerProcessStartDate">Inicio de proceso de la aseguradora</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                      <input
                        type="text"
                        name="insurer_folio"
                        value={formData.insurer_folio || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingInsurerFolio"
                        placeholder=" "
                        onChange={handleChange}
                      />
                      <label htmlFor="floatingInsurerFolio">Folio Aseguradora</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                      <select
                        type="text"
                        name="request_method"
                        value={formData.request_method || ''}
                        disabled={!editForm}
                        className="form-select"
                        id="floatingRequestMethod"
                        placeholder=" "
                        onChange={handleChange}
                      >
                        <option value="Email">Email</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Teléfono">Teléfono</option>
                        <option value="Reunión">Reunión</option>
                        <option value="otro">Otro</option>
                      </select>
                      <label htmlFor="floatingRequestMethod">Método de Solicitud</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                      <input
                        type="date"
                        name="insurer_process_start_date"
                        value={formData.insurer_process_start_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingInsurerProcessStartDate"
                        placeholder=" "
                        onChange={handleChange}
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingInsurerProcessStartDate">Días transcurridos (Aseguradora)</label>
                    </div>
                  </div>
                  <div style={{ width: '100%', borderBottom: '1px solid #ddd', marginBottom: '20px' }}>
                    <div className="d-flex align-items-center text-center">
                      <h3 style={{ margin: '0', textAlign: 'center' }}>Cierre</h3>
                      <ButtonMui
                        variant="contained"
                        color="success"
                        onClick={addRow}
                        startIcon={<AddIcon />}
                        style={{ marginLeft: '1rem' }}
                      >
                      </ButtonMui>
                    </div>
                  </div>

                  <Row className="mb-3">
                    {rows.map((row, index) => (
                      <React.Fragment key={index}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                          <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                            <input
                              type="text"
                              name="policy_number"
                              value={row.policy_number}
                              onChange={(e) => handleRowChange(index, e)}
                              className="form-control"
                              placeholder=" "
                              style={{ fontSize: 'inherit' }}
                              disabled
                            />
                            <label>No de Póliza</label>
                          </div>
                          <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                            <input
                              type="text"
                              name="endorsement_number"
                              value={row.endorsement_number}
                              onChange={(e) => handleRowChange(index, e)}
                              className="form-control"
                              placeholder=" "
                              style={{ fontSize: 'inherit' }}
                              disabled
                            />
                            <label>No de Endoso</label>
                          </div>
                          <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                            <input
                              type="date"
                              name="issue_date"
                              value={row.issue_date}
                              onChange={(e) => handleRowChange(index, e)}
                              className="form-control"
                              placeholder=" "
                              disabled
                              style={{ fontSize: 'inherit' }}
                            />

                            <label>Inicio de proceso de la aseguradora</label>
                          </div>
                          <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                            <input
                              type="text"
                              name="pdf"
                              value={row.pdf}
                              onChange={(e) => handleRowChange(index, e)}
                              className="form-control"
                              placeholder=" "
                              style={{ fontSize: 'inherit' }}
                              disabled
                            />
                            <label>PDFs</label>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </Row>

                </div>
              </Col>
              <Col >
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>

                  <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                    <input
                      type="text"
                      name="status"
                      value={formData.status || ''}
                      disabled={!editForm}
                      className="form-control"
                      id="floatingStatus"
                      placeholder=" "
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingStatus">Estatus</label>
                  </div>
                  <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                    <input
                      type="text"
                      name="insurer_folio"
                      value={formData.insurer_folio || ''}
                      disabled
                      className="form-control"
                      id="floatingInsurerFolio"
                      placeholder=" "
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInsurerFolio">Dias transcurridos</label>
                  </div>
                </div>
                <div style={{ border: '1px solid black', padding: '20px', height: '100%' }}>
                  <h4>Bitacora</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique quam id odio placerat ultricies. Praesent id scelerisque orci, vitae congue ex. Phasellus eu nisl non mauris efficitur faucibus. In sit amet convallis leo. In ac turpis tristique, condimentum augue vel, fermentum nulla. Duis scelerisque, dolor id suscipit pretium, mauris turpis auctor sapien, a sagittis elit ante nec lorem. Nulla in nibh vel metus imperdiet molestie.
                  </p>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                    <input
                      type="text"
                      name="created_at"
                      value={data.created_at || ''}
                      disabled={!editForm}
                      className="form-control"
                      id="floatingCreated"
                      placeholder=" "
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingCreated">Creacion</label>
                  </div>
                  <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                    <input
                      type="text"
                      name="updated_at"
                      value={data.updated_at || ''}
                      disabled={!editForm}
                      className="form-control"
                      id="floatingUpdate"
                      placeholder=" "
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingUpdate">Ultima Modificacion</label>
                  </div>
                </div>
              </Col>




            </Row>
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h5><strong>Asegurados</strong></h5>
                </Accordion.Header>
                <Accordion.Body>
                  <Row md={2}>
                    <div className="section-title">
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="text"
                        name="created_at"
                        value={formatDate(data?.created_at) || ''}
                        disabled={true}
                        className="form-control"
                        id="floatingCreatedAt"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingCreatedAt">Creación</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="text"
                        name="updated_at"
                        value={formatDate(data?.updated_at) || ''}
                        disabled={true}
                        className="form-control"
                        id="floatingUpdatedAt"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                      />
                      <label htmlFor="floatingUpdatedAt">Última Actualización</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="date"
                        name="expected_date"
                        value={formData.expected_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingExpectedDate"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                        onChange={handleChange}

                      />
                      <label htmlFor="floatingExpectedDate">Fecha Estimada</label>
                    </div>

                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="date"
                        name="insurer_process_start_date"
                        value={formData.insurer_process_start_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingInsurerProcessStartDate"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                        onChange={handleChange}

                      />
                      <label htmlFor="floatingInsurerProcessStartDate">Inicio de proceso de la aseguradora</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="date"
                        name="response_date"
                        value={formData.response_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingResponseDate"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                        onChange={handleChange}

                      />
                      <label htmlFor="floatingResponseDate">Respuesta</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="date"
                        name="procedure_completion_date"
                        value={formData.procedure_completion_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingProcedureCompletionDate"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                        onChange={handleChange}

                      />
                      <label htmlFor="floatingProcedureCompletionDate">Terminación de Trámite</label>
                    </div>
                    <div className="mb-3 form-floating" style={{ fontSize: '12px' }}>

                      <input
                        type="date"
                        name="client_delivery_date"
                        value={formData.client_delivery_date || ''}
                        disabled={!editForm}
                        className="form-control"
                        id="floatingClientDeliveryDate"
                        placeholder=" "
                        style={{ fontSize: 'inherit' }}
                        onChange={handleChange}

                      />
                      <label htmlFor="floatingClientDeliveryDate">Entrega al Cliente</label>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
            <hr />

          </Container>
        </Modal.Body>
        <Modal.Footer>
          {!editForm ? (
            <Button variant="info" onClick={handleEdit}>Editar</Button>
          ) : (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button variant="primary" onClick={handleEdit}>Cancelar</Button>
              <Button variant="success" onClick={handleSubmit}>Enviar Cambios</Button>
            </div>
          )}
          <Button variant="danger" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </div >

  );
};

export default ModalActionsProcedures;
